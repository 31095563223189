/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

const initDownload = () => $('a.app-integration-export-download').off('click').on('click', function() {
  $.fileDownload($(this).attr("href"), {
    data: {format: 'xlsx'},
    successCallback(url) {
      show_alert(I18n.t("app_integration_download_successful"));
      return enable_refresh();
    }
    ,
    failCallback(url) {
      return show_error(I18n.t("download_failed", {type: I18n.t("app_integration").toLowerCase()}));
    }
  });
  return false;
});

var enable_refresh = function() {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on('click', () => setTimeout(refresh, 30000));
  }
};

const app_integration_init = function() {
  initToolTip();
  $(".app_integration_access_type").hide();
  $(".app_integration_company_app_integration_company_id").hide();
  $('#company-select-container').hide();

  let selectedCompanies = [];
  var $select = $('#company_selection');
  const maxSelectionLimit = parseInt($("#companies_limit").val());

  $select.multipleSelect({
    selectAll: false,
    filter: true,
    filterPlaceholder: I18n.t('search'),
    showClear: true,
    onClick: function(view) {
      if (view.selected) {
        selectedCompanies.push(view.value);
      } else if (!view.selected) {
        selectedCompanies = selectedCompanies.filter(x => x != view.value);
      }
      const selectedCount = selectedCompanies.length;

      const dropdownCheckboxes = $(".ms-drop input[type='checkbox']");
      if (selectedCount >= maxSelectionLimit) {
        dropdownCheckboxes.each(function () {
          if (!$(this).prop("checked")) {
              $(this).prop("disabled", true); 
              $(this).closest("label").addClass("disabled"); 
          }
        }); 
      } else {
        dropdownCheckboxes.prop("disabled", false);
        $(".ms-drop label").removeClass("disabled");
      }
      $('#app_integration_company_app_integration_company_ids').val(selectedCompanies.join()).trigger('change');
    },
    onClear: function() {
      selectedCompanies = [];
      $('#app_integration_company_app_integration_company_ids').val('').trigger('change');
      $(".ms-drop input[type='checkbox']").prop("disabled", false);
      $(".ms-drop label").removeClass("disabled");
    },
    onFilter: function() {
      handleMaxSelectionLimit();
      
      if($(".ms-drop input[type='checkbox']").length > 0){
        $(".no-results").remove();
      } else {
        $(".ms-drop ul").append('<li class="no-results">'+ I18n.t('no_results_found') +'</li>');
      }
    },
    formatCountSelected: function (numSelected, numTotal) {
      return numSelected +" "+ I18n.t('companies_selected');
    }
  });

  $('button.ms-choice').click(function() {
    let loaded = false;

    if (!loaded) {

      // start spinner
      const spinner = $('<span class="comp_spinner"><i class="fa fa-spinner fa-spin"></i> '+ I18n.t('loading') +'...</span>');
      if ($select.parent().find("div.ms-drop").find('.' + "comp_spinner").length === 0){
        $select.parent().find("div.ms-drop").append(spinner);
      }

      $.ajax({
        url: "/app_integrations/fetch_companies",
        dataType: "json",
        success: function(data) {
          data.forEach(company => {
            let $opt = $('<option />', { value: company.id, text: company.name });
            $select.append($opt)
          });
        },
        complete: function() {
          $select.multipleSelect('refresh');
          loaded = true;
          spinner.remove(); // remove spinner
        }
      });
    } // loaded
  });

  $('#app_integration_installation_type').off('change').on('change', function(e) {
    if($(this).val() == '1'){
      $(".app_integration_access_type").show();
    } else {
      $(".app_integration_access_type").hide();
      $('#app_integration_access_type').prop('selectedIndex', 0);

      $('#company-select-container').hide();
      $('#company_selection').multipleSelect('uncheckAll');
      $('#app_integration_company_app_integration_company_ids').val('');
      $('#company-select-container').find('div.icon-close').hide();
      $(".ms-drop input[type='checkbox']").prop("disabled", false);
      $(".ms-drop label").removeClass("disabled");
      selectedCompanies = [];
    }
  });

  $('#app_integration_access_type').off('change').on('change', function(e) {
    if($(this).val() == '1'){
      $('#app_integration_access_type').parent().find('.help-block').remove();
      $('#app_integration_access_type').parent().removeClass("has-error");
      $('#company-select-container').show();
    } else {

      if ($(this).val() == '') {
        if (!($('.app_integration_access_type').hasClass('has-error'))) {
          $('.app_integration_access_type').addClass(" has-error");
          $('#app_integration_access_type').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
        }
      } else {
        $('#app_integration_access_type').parent().find('.help-block').remove();
        $('#app_integration_access_type').parent().removeClass("has-error");
      }

      $('#company-select-container').hide();
      $('#company_selection').multipleSelect('uncheckAll');
      $('#app_integration_company_app_integration_company_ids').val('');
      $('#company-select-container').find('div.icon-close').hide();
      $(".ms-drop input[type='checkbox']").prop("disabled", false);
      $(".ms-drop label").removeClass("disabled");
      selectedCompanies = [];
    }
  });

  $('#app_integration_company_app_integration_company_ids').change(function() {
    const selectedValues = $(this).val();
    if(selectedValues.length == 0) {
      if (!($('#company-select-container').hasClass('has-error'))) {
        $('#company-select-container').addClass("has-error");
        $('#app_integration_company_app_integration_company_ids').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>");
        $('#company-select-container').find('div.icon-close').hide();
      }
    } else {
      $('#company-select-container').find('.help-block').remove();
      $('#company-select-container').removeClass("has-error");
      $('#company-select-container').find('div.icon-close').show();
    }
  });

  $("#new-form-submit-app-integration").off("click").on("click", function (e) {
    $("#new_app_integration").find("input, select").each(function () {
      $(this).trigger("blur");
    });
    var access_type_present = true;
    var company_selection_present = true;

    if ($("#app_integration_installation_type").val() == '1') {
      // check the access type presence 
      if ($("#app_integration_access_type").val() == '') {
        access_type_present = false;
        if (!($('.app_integration_access_type').hasClass('has-error'))) {
          $('.app_integration_access_type').addClass(" has-error");
          $('#app_integration_access_type').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
        }
      } else if ($("#app_integration_access_type").val() == '1') {
        access_type_present = true;
        $('#app_integration_access_type').parent().find('.help-block').remove();
        $('#app_integration_access_type').parent().removeClass("has-error");

        if ($('#app_integration_company_app_integration_company_ids').val().length > 0){
          company_selection_present = true;
          $('#company-select-container').find('.help-block').remove();
          $('#company-select-container').removeClass("has-error");
        } else {
          if (!($('#company-select-container').hasClass('has-error'))) {
            $('#company-select-container').addClass("has-error");
            $('#app_integration_company_app_integration_company_ids').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>");
          }
          company_selection_present = false;
        }
      }
      // Add form submit flow 
      if (access_type_present && company_selection_present ) {
        $("#new_app_integration").submit();
      } else {
        return false;
      }
    } else {
      $("#new_app_integration").submit();
    }
  });
}

const document_ready = function () {
  var crud_options = {
    'app-integration': {
      new:  app_integration_init
    }
  };
  init_datatable(crud_options);
  var clipboard = new Clipboard(".btn-copy-app-id");
};

var refresh = () => $.get("/app_integrations/download_app_export.json", function (obj, status, xhr) {
    if (sessionExpired(xhr)) {
      return;
    }
    const { download_path } = obj;
    if (download_path !== undefined) {
      show_alert(
        '<a class="app-integration-export-download" href="' +
          download_path +
          '">' +
          I18n.t("export_completed", {type: I18n.t("app_integration").toLowerCase()}) +
          "</a>"
      );
      initDownload();
      return;
    }

    if (download_path === undefined) {
      return setTimeout(refresh, 30000);
    }
  });

var init_datatable = function init_datatable(crud_options) {
  if (crud_options == null) {
    crud_options = {};
  }
  return dt.initDatatable($("#app-integrations-table"), {}, crud_options);
};

jQuery(function () {
  return run_ontag("app_integrations", function () {
    document_ready();
    if ($("#created").length) {
      setTimeout(refresh, 30000);
    }
    $(document).on("pjax:end", document_ready);
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  });
});
var autocomplete_remove_error,
    autocomplete_show_error,
    change_autocomplete_label,
    document_ready,
    filter_format_by_vfnn,
    get_checked_status,
    initDownload,
    init_datatable,
    init_search,
    load_deactive_user_request,
    no_data_found_check,
    prepare_agency,
    prepare_country,
    prepare_modal_vars,
    prepare_sg_feature_list,
    prepare_svc_grade,
    prepare_vfnns,
    refresh,
    set_branch,
    set_title,
    toggle_autocomplete_message,
    update_feature_list,
    user_request_edit_init,
    user_request_new_init,
    check_addons_validation,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

initDownload = function() {
  return $('a.user-request-export-download').off('click').on('click', function() {
    $.fileDownload($(this).attr("href"), {
      data: {
        format: 'xlsx'
      },
      successCallback: function(url) {
        show_alert(I18n.t("user_request_download_successful"));
        return refresh();
      },
      failCallback: function(url) {
        return show_error(I18n.t("user_request_download_failed"));
      }
    });
    return false;
  });
};


user_request_new_init = function() {
  initToolTip();
  var mode;
  mode = "new";
  prepare_modal_vars(mode);
  set_title('new');
  prepare_country(mode);
  prepare_agency();
  prepare_vfnns("new");
  prepare_svc_grade(mode);
  prepare_sg_feature_list(mode);
  load_deactive_user_request(mode);
  prepare_activate_sim();
  enable_device_fields();
  validate_sg_form();
  add_next_previous_btns_us();
  get_device_type();
  refresh_form_on_resubmit();
  set_fields_on_resubmit();
  check_addons_validation();
  load_company_defaults();
  return change_autocomplete_label();
};


//refilling the form while resubmitting the add device to existing customer request
set_fields_on_resubmit = function() {
  var customer_value = $("#existing_ulink_sid").val();
  var request_type = $("#user_request_request_type").val();

  if (customer_value && request_type == "13") {
    $(".device-fields").show();
    $('#user_request_autocomplete_ultralink').val(customer_value);
    $('#ultralink_id').val($("#existing_ulink_id").val());
    autocomplete_remove_error();
    $("#customer_ultralink_sid").blur();
    $('#user_request_agency_id').val($("#ur_agency").val());
    return load_company_defaults()
  }
}

//loading the service grades while resubmitting the user
var refresh_form_on_resubmit = function () {
  var usid = $('#customer_ultralink_sid').val();
  var request_type = $("#user_request_request_type").val();
  if (usid !== "" && (request_type == "1" || request_type == "13")) {
    $("#new_user_request")
      .find("input, select")
      .each(function () {
        $(this).trigger("blur");
      });
  }
  if (request_type == "3"){
    $(" #report_channel_service_grade").off("change").on('change', function() {
      var sg_inputtag = $('#userrequest_sg_tagsinput');
      var tagsArray = sg_inputtag.tagsinput('items');
      if (tagsArray.length > 0 && $(this).val() !== '' ) {
        $("#new-form-submit-user-request").attr("disabled", false);
      }
    });
  }
};

get_device_type = function() {
  if($("#user_request_request_type").val() == '1'){
    if($("#can_access_wizard").val() == 'true')
    {
      //Make wizard_sid to be blank to avoid multiple ajax calls for no change on SID input.
      $("#wizard_sid").val('');
      $("#device_type_label").hide();
      process_sid_input();
    } else {
      prepare_site_type();
    }
  } else
  if($("#user_request_request_type").val() == '13')
  {
    $('#customer_ultralink_sid').on('keyup blur', function() {
      var usid = $('#customer_ultralink_sid').val();
      if (usid !== "" && usid.length == 12) {
        // update_servicegrade(usid, "#customer_ultralink_report_channel_service_grade",
        //                 "#customer_ultralink_report_channel_branch_id");
        $.ajax({
          url: "/ultralinks/get_device_details",
          type: "GET",
          data: { sid: usid },
          success(data, status, xhr) {
            if (!sessionExpired(xhr)) {
              $('#site_type').val(data["device_type"]);
              show_device_type_label(data["device_type"],"customer_ultralink_sid");

              if (data["device_type"] == 1){
                $('#is_recorder').val(true);
              } else {
                $('#is_recorder').val(false);
              }
              $("#customer_ultralink_report_channel_service_grade option:not(:first-child)").remove();
              for (i = 0; i <= data["service_grades"].length - 1; i++) {
                var option_att = data["service_grades"][i][2]
                var option_val = data['service_grades'][i][0]
                $('<option>').attr({
                  'data-is-lower-grade': option_att['data-is-lower-grade'],
                  'data-is-recorder': option_att['data-is-recorder'],
                  'data-is-camera': option_att['data-is-camera'],
                  'data-standard-fcode': option_att['data-standard-fcode'],
                  value: option_val
                }).text(option_val).appendTo('#customer_ultralink_report_channel_service_grade');
              }
              $("#customer_ultralink_report_channel_vfnn").prop('selectedIndex', 0)
              $("#customer_ultralink_report_channel_vfnn").trigger('change');
              camera_agency_filter($('#site_type').val(), $("#user_request_agency_id option"))

              // site_type_filter();
              disable_recorder_sg();
              prepare_service_grades();
              $("#customer_ultralink_report_channel_service_grade").trigger('change');
            }
          }
        });
      }
    });
    // Hide site type filter if No self mon linked to dealer.
    // validate_self_mon_exists($("#modal-body-new-user-request #user_request_agency_id option"));
    // site_type_filter()
    const user_request_model = "user-request";
    create_sg_feature_list("#customer_ultralink_report_channel_service_grade", "#site_type");

    //resubmit form fields load only recorder values if site type as recorder.
    if ($('#site_type').val() == "1") {
      sg_filter_type("1");
    } else {
      sg_filter_type("0");
      restrict_addons_for_core("user_request");
      oh_reporting_input_fields("customer_ultralink_report_channel", "user_request");
    }
    // Skipping this for dealer agency linking request
    if ($("#is_cms_linking").val() != "true") {
      $("#user_request_agency_id").prop('selectedIndex',0);
    }

    // Validate account number for dealer adding remote device requests
    initAcctnoValidation("new_user_request", "customer_ultralink_report_channel_acctno", "#new-form-submit-user-request", true);
    filter_format_by_vfnn();
    return load_company_defaults();
  }
}

hide_wizard_step = function(step_label){
  $('.f1-step p').filter(function() {
    if($(this).text().trim() === step_label){
      var get_currentclass = $(this).closest('.f1-step').attr("class")
      var lastTwoChars = get_currentclass.slice(-2);
      update_laststep(lastTwoChars,"hide")
    }

    return $(this).text().trim() === step_label;
  }).closest('.f1-step').hide();
  adjust_size_progress_bar();
}

show_wizard_step = function(step_label){
  $('.f1-step p').filter(function() {
    if($(this).text().trim() === step_label){
      var get_currentclass = $(this).closest('.f1-step').attr("class")
      var lastTwoChars = get_currentclass.slice(-2);
      update_laststep(lastTwoChars, "show")
    }

    return $(this).text().trim() === step_label;
  }).closest('.f1-step').show();

  var numberOfSteps = $('.f1-steps .f1-step').length;
  if (numberOfSteps > 4){
    $('.f1-step').css({
        'position': 'relative',
        'float': 'left',
        'width': '20%' ,
        'padding': '0 5px'
    });
  } else {
    $('.f1-step').css({
        'position': 'relative',
        'float': 'left',
        'width': '25%',
        'padding': '0 5px'
    });
  }
}

update_laststep = function(lastTwoChars, display){
  if(display == "show"){
    var selected = $("." + "step_" + lastTwoChars);
    var stepElements = $('[class*="step_"]');
    stepElements.removeClass('last_step');
    if ($("#site_type").val() == 0){
      var step05 = $('[class*="step_05"]');
      step05.addClass('last_step');
    } else {
      selected.addClass('last_step');
    }

  } else {
    var selected = $("." + "step_" + lastTwoChars);
    selected.addClass('hidden-element');
    if(selected.hasClass('last_step')){
      selected.removeClass('last_step');
      var prvclass = selected.prev('div[class^="step_"]');
      prvclass.addClass('last_step');
    }
  }
}

add_next_previous_btns_us = function() {
  if($("#can_access_wizard").val() == 'true')
  {
    var request_type = $("#user_request_request_type").val();
    // The Next and Previous buttons and its on click functionality derived in this method.
    if (request_type == "1"){
      var numberOfSteps = $('.f1-steps .f1-step').length;

      // Based on size of steps reducing the width of each step navigation div
      // Needs to move with CSS
      if (numberOfSteps > 4){
        $('.f1-step').css({
            'position': 'relative',
            'float': 'left',
            'width': '20%' ,
            'padding': '0 5px'
        });
      } else {
        $('.f1-step').css({
            'position': 'relative',
            'float': 'left',
            'width': '25%',
            'padding': '0 5px'
        });
      }

      $("#new-form-next-user-request").show();
      $("#new-form-previous-user-request").hide();
      $("#new-form-submit-user-request").hide();
      // When user clicks on Next button logic
      $('#new-form-next-user-request').off("click").on("click", function (e) {
        var classNames = [];
        var stepClasses = $('[class*=step_0]').not('.hidden-element');
        stepClasses.each(function() {
          classNames.push($(this).attr("class"));
        });
        var activeClass = classNames[0].split(" ");

        if(validateActiveForm(activeClass[0])){
          var progress_line = $(".f1-steps").find('.f1-progress-line');
          var current_active_step = $(".f1-steps").find('.f1-step.active');
          $('.' + activeClass.join(".")).fadeOut(400, function() {
            next_class = current_active_step.removeClass('active').addClass('activated').next();
            // Check if next parent div having display non class, then move next class and add active
            if(next_class.css('display') !== 'none'){
              // check if next class having display not none
              next_class.addClass('active')
            } else {
              next_class.next().addClass('active')
            }
            progress_bar(progress_line, 'right');
            $("#new-form-previous-user-request").show();
            $('.' + activeClass[0]).addClass('hidden-element');
            $('.' + activeClass[0]).removeClass('active');
            var nextStepElement  = $('.' + activeClass[0]).next('[class^="step_"]');
            if(next_class.css('display') == 'none'){
              nextStepElement = nextStepElement.next()
            }
            // If current class contains last_step string system shold show submit button
            current_active = nextStepElement.attr("class")
            if(current_active.includes('last_step') ){
              $("#new-form-submit-user-request").show();
              $("#new-form-next-user-request").hide();
            } else {
              $("#new-form-submit-user-request").hide();
              $("#new-form-next-user-request").show();
            }

            var currentClass = nextStepElement.attr("class").split(" ")[0];
            $('.' + currentClass).removeClass('hidden-element');
            $('.' + currentClass).addClass('active');
            $('.' + currentClass).removeAttr('style');

            if (currentClass == "step_02"){
              if ($(".plan_helper_txt").text() === ""){
                service_level_url = $("#service_level_url").val();
                $('#customer_ultralink_subscription_plan').
                  after("<a class='plan_helper_txt' href ='"
                    + service_level_url + "' title ='" + I18n.t('subscription_plan_help')
                    + "' target='_blank'>&nbsp;<i class='fa fa-question-circle fa-1x'></a>");
              }
            }
            if (currentClass == "step_03"){
              var recorder_formats = $("#new_user_request").data('recorder-formats');
              var camera_formats = $("#new_user_request").data('camera-formats');
              var vfnn_select      = $("#customer_ultralink_report_channel_vfnn");
              var format_select    = $("#customer_ultralink_report_channel_format");
              var format_options   = $("#customer_ultralink_report_channel_format option");

              let has_formats = false;

              $("#customer_ultralink_report_channel_vfnn").off("change").on('change', function() {
                const vfnn_protocol     = vfnn_select.find("option:selected").attr("data-protocol");
                if (vfnn_protocol == undefined ){
                  hide_select_options("#customer_ultralink_report_channel_format")
                } else {
                    format_select.attr("readonly", false);
                    format_options.slice(1).each(function() {
                      const format_option = $(this);
                      const option_protocols = format_option.attr("data-protocols");
                      if ((option_protocols !== undefined) && (option_protocols.indexOf(vfnn_protocol) >= 0)) {
                         format_option.prop("selected", false).show().prop("disabled", false);
                         return has_formats = true;
                      } else {
                        return format_option.prop("selected", false).hide().attr("disabled", true);
                      }
                    });
                  var site_type = $("#site_type").val();
                   device_type_formats(site_type, "customer_ultralink_report_channel_format",
                                              recorder_formats, camera_formats )
                }
              });
              load_company_defaults()
            }
            if (currentClass == "step_04"){
              if ($("#customer_ultralink_subscription_plan").val() == "Core Plus") {
                subscription = []
                $('#customer_ultralink_report_channel_fault_reporting_time option').each(function(){
                  if($(this).attr("data-subscription") == 'core_plus'){
                    subscription.push($(this).attr("data-connectivity"))
                  }
                });
                $.each(subscription, function(index, element) {
                  if(element === 'cellular') {
                    $("#cellular_id").show();
                    return false; //Return out of loop if element has cellular for core plus.
                  } else {
                    $("#cellular_id").hide();
                  }
                });
              } else{
                $("#cellular_id").show();
              }
              $('#ethernet').change(function(){
                $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
              })

              $('#cellular').change(function(){
                $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
              })
            }

            // Load Add-Ons basd on previous
            if (currentClass == "step_05"){
              var device_type = $('#site_type').val();
              load_sg_addon_for_wizard(device_type)
            }
          });
        }
      });

      $("#user_request_agency_id").off("change").on('change', function() {
        prepare_agency();
        const branch_id = $("#user_request_agency_id option:selected").attr("data-branch-id");
        $("#customer_ultralink_report_channel_branch_id").val(branch_id);
      });

      $(".customer_ultralink_subscription_plan").off("change").on('change', function() {
        if($("#site_type").val() == 0){
          // If selected device as panel and it has custom sg then hide connectivity tab
          if($(this).find(':selected').attr("data-is-custom-sg") == 'true'){
            fcode = $(this).find(':selected').attr("data-standard-fcode")
            hide_wizard_step(I18n.t('connectivity'))

            if ($(this).find(':selected').attr("data-is-lower-grade") == 'true'){
              //show only self mon cms when lower service grade for custom service grades
              camera_agency_filter('2', $("#user_request_agency_id option"));
            }else {
              camera_agency_filter($("#site_type").val(), $("#user_request_agency_id option"));
            }

            $("#custom_sg").val($(this).find(':selected').val());
            $("#fcode").val(fcode);
            $(".sg_features_block").show();
            refresh_feature_list("#site_type",fcode);
            return hideAddonResetFeatures();
          } else {
            show_wizard_step(I18n.t('connectivity'))
          }
        }

        // On change of subscription plan reset the selected connectivity check box
        // And reset Fault reporting time as well.
        $('#cellular').prop('checked', false);
        $('#ethernet').prop('checked', false);
        $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
        $(".filter_service_grades").hide();

        // Clear acctno if user comes back and changes subscription plan
        $("#customer_ultralink_report_channel_acctno").val('')
        $(".customer_ultralink_report_channel_acctno").removeClass("focused")

        if( ($("#site_type").val() == 1) || ($("#site_type").val() == 2) ){
          $("#custom_sg").val($(this).find(':selected').val());
        }

        if($(this).find("option:selected").val() == "") {
          if (!($('.customer_ultralink_subscription_plan').hasClass('has-error'))) {
            $('.customer_ultralink_subscription_plan').addClass(" has-error");
            $('.plan_helper_txt').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
          }
        } else {
          $('.plan_helper_txt').parent().find('.help-block').remove();
          $('#customer_ultralink_subscription_plan').parent().removeClass("has-error");

          // If selected subscription plan is "Core" then show only selfMon CMS.
          // If selected subscription plan is non-Core then show all the CMS.
          // If selected subscription plan is "Core" then fault reporting time should hide
          branch_details = $("#user_request_agency_id option");
          if($(this).find("option:selected").val() == "Core"){
            $(".customer_ultralink_report_channel_fault_reporting_time").hide();
            branch_details.each(function () {
              var isEmptyValue = $(this).val() === "";
              if (isEmptyValue){
                $(this).prop("selected", true);
              }
              if( $(this).attr("data-is-self-monitoring") == "true" ) {
                $(this).show().prop("disabled", false);
              } else if (!(isEmptyValue)) {
                $(this).hide().prop("disabled", true);
              }
            });
          } else {
            $(".customer_ultralink_report_channel_fault_reporting_time").show();

            branch_details.each(function () {
              if ($(this).val() === ""){
                $(this).prop("selected", true);
              }
              $(this).show().prop("disabled", false);
            });
          }

          prepare_agency();
        }
      });

      $("#filter_service_grades").off("change").on('change', function() {
        if (!($(this).val() === '')){
          var fcode = $(this).find(':selected').attr("data-standard-fcode");
          var service_grade = $(this).val();

          $("#custom_sg").val(service_grade);
          $("#fcode").val(fcode);
          $(".sg_features_block").show();
          return refresh_feature_list("#site_type", fcode)
        }
      });

      $("#customer_ultralink_report_channel_fault_reporting_time").off("change").on('change', function() {
        if ($("#site_type").val() == 0 ) {
          var device_type = $('#site_type').val();
          var subscription_plan = $("#customer_ultralink_subscription_plan").val();
          var ethernetChecked = $('#ethernet').is(':checked');
          var cellularChecked = $('#cellular').is(':checked');
          var fault_reporting_time = ""
          if (!($("#customer_ultralink_subscription_plan").val() == "Core")) {
            fault_reporting_time = $(this).val();
          }
          //Remove and Add cont be blank msg while selecting and un-select the fault reporting time.
          if ($(this).val() === '' || $(this).val() == null) {
            $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
            if (!($('.customer_ultralink_report_channel_fault_reporting_time').hasClass('has-error'))) {
              $('.customer_ultralink_report_channel_fault_reporting_time').addClass(" has-error");
              $('#customer_ultralink_report_channel_fault_reporting_time').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
            }
            fault_time = false;
          } else {
            $('#customer_ultralink_report_channel_fault_reporting_time').parent().find('.help-block').remove();
            $('#customer_ultralink_report_channel_fault_reporting_time').parent().removeClass("has-error");
            fault_time = true;
          }
          if (!(fault_reporting_time === '') && (ethernetChecked || cellularChecked))
          {
            if(($("#customer_ultralink_subscription_plan").find(':selected').attr("data-is-custom-sg") == "false")){
              $.ajax({
               url: "/ultralinks/generate_service_grade",
                type: "GET",
                data: { device_type: device_type,
                        subscription_plan: subscription_plan,
                        ethernetChecked: ethernetChecked,
                        cellularChecked: cellularChecked,
                        fault_reporting_time: fault_reporting_time },
                success(data, status, xhr) {
                  if (!sessionExpired(xhr)) {
                    if(data['size'] > 1) {
                      var select = $('#filter_service_grades');
                      $('.filter_service_grades').show();
                      select.empty();
                      select.prepend($('<option>', {
                        value: '',
                        text: I18n.t("filter_service_grades")
                      }));

                      $.each(data["service_grade"], function(key, value) {
                        select.append($('<option>', {
                          'data-standard-fcode': value,
                          value: key,
                          text: key
                        }));
                      });

                      if ($('.filter_service_grades').is(':visible')){
                        if($('#filter_service_grades').val() === ''){
                          if (!($('.filter_service_grades').hasClass('has-error'))) {
                            $('.filter_service_grades').addClass(" has-error");
                            $('#filter_service_grades').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
                          }
                        }
                        else
                        {
                          $('#filter_service_grades').parent().find('.help-block').remove();
                          $('#filter_service_grades').parent().removeClass("has-error");
                        }
                      }
                    } else {
                      $('.filter_service_grades').hide();
                      $("#custom_sg").val('');
                      $("#fcode").val('');
                      var service_grade = ""
                      var fcode = ""
                      $.each(data["service_grade"], function(key, value) {
                        service_grade = key
                        fcode = value
                      });
                      $("#custom_sg").val(service_grade);
                      $("#fcode").val(fcode);
                      $(".sg_features_block").show();
                      refresh_feature_list("#site_type",fcode);
                      return hideAddonResetFeatures();
                    }
                  }
                }
              });
            }
          }
        }
      });

      // When user clicks on Back button logic
      $('#new-form-previous-user-request').off("click").on("click", function (e) {
        var progress_line = $(".f1-steps").find('.f1-progress-line');
        var stepClasses = $('[class*=step_0]').not('.hidden-element');
        var classNames = [];
        stepClasses.each(function() {
          classNames.push($(this).attr("class"));
        });
        var activeClass = classNames[0].split(" ");
        var current_active_step = $(".f1-steps").find('.f1-step.active');
        $('.' + activeClass.join(".")).fadeOut(400, function() {
          prev_class = current_active_step.removeClass('active').prev()
          if(prev_class.css('display') !== 'none'){
            // check if prev class having display not none
            prev_class.removeClass('activated').addClass('active');
          } else {
            prev_class.prev().removeClass('activated').addClass('active');
          }
          progress_bar(progress_line, 'left');
          $('.' + activeClass[0]).addClass('hidden-element');
          $('.' + activeClass[0]).removeClass('active');
          var prevStepElement  = $('.' + activeClass[0]).prev('[class^="step_"]');
          if(prev_class.css('display') == 'none'){
            prevStepElement = prevStepElement.prev()
          }
          var preClass = prevStepElement.attr("class").split(" ")[0];


          // If current class contains last_step string system shold show submit button
          current_active = prevStepElement.attr("class")
          if(current_active.includes('last_step') ){
            $("#new-form-submit-user-request").show();
            $("#new-form-next-user-request").hide();
          } else {
            $("#new-form-submit-user-request").hide();
            $("#new-form-next-user-request").show();
          }

          $('.' + preClass).removeClass('hidden-element');
          $('.' + preClass).removeAttr('style');
          $('.' + preClass).addClass('active');
          if (preClass == "step_01") {
            $("#new-form-previous-user-request").hide();
            $("#new-form-next-user-request").show();
          }else {
            $("#new-form-previous-user-request").show();
            $("#new-form-next-user-request").show();
          }
        });
      })

    } else {
      $("#new-form-next-user-request").hide();
      $("#new-form-previous-user-request").hide();
    }

    $('input[type="checkbox"][name="user_request[connectivity]"]').change(function() {
      updateSelectOptions($(this).attr('id'));
    })
  } else {
    $("#new-form-next-user-request").hide();
    $("#new-form-previous-user-request").hide();
    $("#new-form-submit-user-request").show();
  }
}

function updateSelectOptions(connectivity) {
  var checked_count = $('input[name="user_request[connectivity]"]:checked').length
  var subscription_plan = $("#customer_ultralink_subscription_plan").val();

  var subscription_plan_txt = ""
  if(subscription_plan == "Core Plus"){
    subscription_plan_txt = 'core_plus'
  } else if (subscription_plan == "Enhance") {
    subscription_plan_txt = 'enhance'
  }

  if (checked_count == 0) {
    $("#customer_ultralink_report_channel_fault_reporting_time option").slice(1).each(function () {
      $(this).show().prop("disabled", false);
    })
  } else if (checked_count > 1) {
    $("#customer_ultralink_report_channel_fault_reporting_time option").slice(1).each(function () {
      if( subscription_plan_txt === $(this).attr("data-subscription") && $(this).attr("data-connectivity").includes('dualpath') ) {
        $(this).show().prop("disabled", false);
      } else {
        $(this).hide().prop("disabled", true);
      }
    });
  } else if (checked_count <= 1 ) {
    $("#customer_ultralink_report_channel_fault_reporting_time option").slice(1).each(function () {
      if( subscription_plan_txt === $(this).attr("data-subscription") && $(this).attr("data-connectivity").includes(connectivity) ) {
        $(this).show().prop("disabled", false);
      } else {
        $(this).hide().prop("disabled", true);
      }
    })
  }
}

var filter_vfnn_by_agency_id = function (agency_id, modal_vfnn) {
  const vfnn_select = $(modal_vfnn);
  const vfnn_options = $(modal_vfnn + " " + "option");
  let selected_vfnn = "";

  vfnn_options.each(function () {
    if ($(this).attr("data-agency-id") === agency_id) {
      if ($(this).prop("selected")) {
        selected_vfnn = $(this).val();
      }
      return $(this).prop("selected", false).show().prop("disabled", false);
    } else {
      return $(this).prop("selected", false).hide().attr("disabled", true);
    }
  });

  if (agency_id) {
    $(".company_company_default_vfnn").show();
  } else {
    $(".company_company_default_vfnn").hide();
  }
};

function validateActiveForm(current_class) {
  // Validate customer fields for step 01
  var hasError = true;
  //Trigger all input and select tags manually

  if(current_class == "step_01"){
    $("." + current_class).find("input, select").each(function () {
      $(this).trigger("blur");
    });
    //Return false if any child div has a class of "has-error"
    $('.step_01').find('div').each(function() {
      if ($(this).hasClass('has-error')) {
        hasError = false;
      }
    })
  } else if(current_class == "step_02") {
    // Validate customer fields for step 01
    var sid_val = $("#customer_ultralink_sid").val();
    if(sid_val === ''){
      if (!($('.customer_ultralink_sid').hasClass('has-error'))) {
        $('.customer_ultralink_sid').addClass(" has-error");
        $('#customer_ultralink_sid').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
      }
      hasError = false;
    } else {
      if(sid_val.length < 12){
        if (!($('.customer_ultralink_sid').hasClass('has-error'))) {
          $('.customer_ultralink_sid').addClass(" has-error");
          $('#customer_ultralink_sid').after("<span class='help-block'>" + I18n.t('sid_is', {sid: I18n.t("invalid")}) + "</span>" );
        }
        hasError = false;
      } else {
        $('#customer_ultralink_sid').parent().find('.help-block').remove();
        $('#customer_ultralink_sid').parent().removeClass("has-error");
        hasError = true;
      }
    }

    if($("#customer_ultralink_subscription_plan").val() == "") {
      hasError = false
      if (!($('.customer_ultralink_subscription_plan').hasClass('has-error'))) {
        $('.customer_ultralink_subscription_plan').addClass(" has-error");
        $('.plan_helper_txt').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
      }
    } else {
      $('.plan_helper_txt').parent().find('.help-block').remove();
      $('#customer_ultralink_subscription_plan').parent().removeClass("has-error");
      hasError = true
    }

    if (hasError) clearWizardReportFieldErrors();
  } else if(current_class == "step_03") {
    var site_type      = $('#site_type').val();
    var sg_value       = $("#customer_ultralink_subscription_plan").val();
    var selfMon_check  = $("#user_request_agency_id").find("option:selected").attr("data-is-self-monitoring") == "true";

    // VFNN validation checking
    var vfnnValid      = validateReportingFields("customer_ultralink_report_channel_vfnn", true, site_type, sg_value, selfMon_check, false)

    // Acctno validation checking
    var acctValid      = validateReportingFields("customer_ultralink_report_channel_acctno", true, site_type, sg_value, selfMon_check, true)

    // Format validation checking
    var formatValid    = validateReportingFields("customer_ultralink_report_channel_format", true, site_type, sg_value, selfMon_check, false)

    // vfnn,format and acctno combine validity check
    hasError = vfnnValid && formatValid && acctValid;

  } else if(current_class == "step_04") {
    // If user selects site type as panel and subscription plan selected as 'Core'
    // then only cellular checkbox should not be select.
    // Its either Both(cellular and ethernet ) or only ethernet becuase.
    if ($("#site_type").val() == 0 && $("#customer_ultralink_subscription_plan").val() == "Core"){
      $('div[class*="oh_reporting"]').show();
      if (!$('#ethernet').is(':checked') && !$('#cellular').is(':checked')){
        $('.connectivity').addClass('has-error');
        $('.step_04 .connectivity_errormsg').text(I18n.t('connectivity') + " " + I18n.t('not_blank'));
        hasError = false;
      } else if (!$('#ethernet').is(':checked') && $('#cellular').is(':checked')) {
        $('.connectivity').addClass('has-error');
        $('.step_04 .connectivity_errormsg').text(I18n.t('only_cellular_notselect'));
        hasError = false;
      } else {
        $('.connectivity').removeClass('has-error');
        $('.step_04 .connectivity_errormsg').text("");
        hasError = true;
      }
    } else if ($("#site_type").val() == 0 && !($("#customer_ultralink_subscription_plan").val() == "Core")) {

      $('div[class*="oh_reporting"]').hide();
      // Check if at least one checkbox with name "my-checkbox" is checked
      if ($('input[name="user_request[connectivity]"]:checked').length > 0) {
        $('.connectivity').removeClass('has-error');
        $('.step_04 .connectivity_errormsg').text("");
        connectivity = true;
      } else {
        $('.connectivity').addClass('has-error');
        $('.step_04 .connectivity_errormsg').text(I18n.t('connectivity') + " " + I18n.t('not_blank'));
        connectivity = false;
      }

      if ($("#customer_ultralink_report_channel_fault_reporting_time").val() === '' || $("#customer_ultralink_report_channel_fault_reporting_time").val() == null) {
        $('#customer_ultralink_report_channel_fault_reporting_time').prop('selectedIndex', 0);
        if (!($('.customer_ultralink_report_channel_fault_reporting_time').hasClass('has-error'))) {
          $('.customer_ultralink_report_channel_fault_reporting_time').addClass(" has-error");
          $('#customer_ultralink_report_channel_fault_reporting_time').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
        }
        fault_time = false;
      } else {
        $('#customer_ultralink_report_channel_fault_reporting_time').parent().find('.help-block').remove();
        $('#customer_ultralink_report_channel_fault_reporting_time').parent().removeClass("has-error");
        fault_time = true;
      }
      if (connectivity && fault_time ){
        if ($('.filter_service_grades').is(':visible')){
          if($('#filter_service_grades').val() === ''){
            if (!($('.filter_service_grades').hasClass('has-error'))) {
              $('.filter_service_grades').addClass(" has-error");
              $('#filter_service_grades').after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
              return hasError = false;
            } else {
              return hasError = false;
            }
          }
          else
          {
            $('#filter_service_grades').parent().find('.help-block').remove();
            $('#filter_service_grades').parent().removeClass("has-error");
            return hasError = true
          }
        } else {
           return hasError = true
        }
      } else {
        hasError = false
      }
    }
  }
  return hasError
}

function scroll_to_class(element_class, removed_height) {
  var scroll_to = $(element_class).offset().top - removed_height;
  if($(window).scrollTop() != scroll_to) {
    $('html, body').stop().animate({scrollTop: scroll_to}, 0);
  }
}

validate_sg_form = function() {
  // Temp fix for the model box issue when document loads
  if($("#user_request_request_type").val() === "3"){
    validateAndSubmit();
  } else if ($("#user_request_request_type").val() === "1"){
    if($("#form_type").val() == "wizard") {
      // On Submit validation for VFNN and format for non panel device types
      validateAndSubmit();
    } else {
      // Validate account number for Non-EU dealer site privision user request
      initAcctnoValidation("new_user_request", "customer_ultralink_report_channel_acctno", "#new-form-submit-user-request");
    }
  }
}

prepare_activate_sim = function() {
  if($("#user_request_request_type").val() === "12") {
    $(".user_request_report_channel_id").hide();
    $("#user_request_autocomplete_sim_iccid").on("keypress", function(event) {
      if ( (event.which >= 48 && event.which <= 57) || event.which === 8 ) {
        change_autocomplete_placeholder("#user_request_autocomplete_sim_iccid",
                          I18n.t('activatesim_autocomplete_message'), I18n.t("iccid"))
        return true;
      } else {
        event.preventDefault();
      }
    });

        $(".user_request_report_channel_id").hide();
        validateAndSubmit();

        $('#user_request_autocomplete_sim_iccid').on('railsAutocomplete.select', function(event, data) {
          $('#sim_partner_id').val(data.item["partner_id"]);
        });

        $('#user_request_autocomplete_sid').on('blur', function() {
          validate_input($('#user_request_autocomplete_sid'), $('.user_request_autocomplete_sid'),$('#sim_sid') );
        });

        $('#user_request_autocomplete_sim_iccid').on('blur', function() {
          validate_input($('#user_request_autocomplete_sim_iccid'), $('.user_request_autocomplete_sim_iccid'),$('#sim_iccid') );
        });

        $('#user_request_autocomplete_sid').on('railsAutocomplete.select', function(event, data) {
          if(data.item.label !== I18n.t('no_results_found')){
            $("#user_request_report_channel_id option:not(:first-child)").remove();
            for (i = 0; i <= data.item['report_channels'].length - 1; i++) {
              var option_att = data.item['report_channels'][i]['channel_id']
              var option_val = data.item['report_channels'][i]['channel_no']
              $('<option>').attr({
                value: option_att
              }).text(option_val).appendTo('#user_request_report_channel_id');
            }
            $('#sim_sid').val(data.item['label']);
          }

          if( data.item.label !== I18n.t('no_results_found') && data.item['report_channels'].length > 1 ) {
            $(".user_request_report_channel_id").show();
          } else {
            $("#user_request_report_channel_id option:eq(1)").prop("selected", true);
            $(".user_request_report_channel_id").hide();
          }

        });
  }
}

validate_input = function(obj_id, obj_class, variable_id) {
  if (variable_id.val().length > 0)
  {
    obj_id.parent().find('.help-block').remove();
    obj_id.parent().removeClass("has-error");
    return false;
  } else {
    if (!(obj_class.hasClass('has-error')))
    {
      obj_class.addClass(" has-error");
      if (!obj_class.is('.user_request_autocomplete_sid, .user_request_autocomplete_sim_iccid')) {
        obj_id.after("<span class='help-block'>" + I18n.t("errors.messages.blank") + "</span>" );
      }
    }
    return true;
  }
}

enable_device_fields = function() {
  $('#user_request_autocomplete_ultralink').on('railsAutocomplete.select', function(event, data) {
    var ultralink_val = $("#user_request_autocomplete_ultralink").val();
    if (ultralink_val.length === 0) {
      $(".device-fields").css("display", "none");
    } else {
      $(".device-fields").css("display", "block");
      return $("#new_user_request").enableClientSideValidations();
    }
  });
}

user_request_edit_init = function() {
  var mode;
  mode = "edit";
  prepare_modal_vars(mode);
  set_title('edit');
  prepare_country(mode);
  prepare_agency();
  prepare_vfnns("change");
  prepare_svc_grade(mode);
  prepare_site_type();
  prepare_sg_feature_list(mode);
  update_reject_button();
  update_approval_button();
  $("#edit-form-submit-user-request").hide();
  if ($("#onboarding_customer_company_name").hasClass("readonly")) {
    $(".edit-object-submit").hide(); }
  else{
    $(".edit-object-submit").show();
  }
  country_select_init("onboarding_customer", "#onboarding_customer_country_code" );
  return change_autocomplete_label();
};


prepare_site_type = function() {
  $('#customer_ultralink_sid').on('keyup blur', function() {
    var usid = $('#customer_ultralink_sid').val();
    if (usid !== "" && usid.length == 12) {
      // update_servicegrade(usid, "#customer_ultralink_report_channel_service_grade",
      //                 "#customer_ultralink_report_channel_branch_id");
        $.ajax({
         url: "/ultralinks/get_device_details",
          type: "GET",
          data: { sid: usid },
          success(data, status, xhr) {
            if (!sessionExpired(xhr)) {
              $('#site_type').val(data["device_type"]);
              if (data["device_type"] == 1){
                $('#is_recorder').val(true);
              } else {
                $('#is_recorder').val(false);
              }

              var recorder_formats = $("#new_user_request").data('recorder-formats');
              var camera_formats = $("#new_user_request").data('camera-formats');

              device_type_formats(site_type, loca_format_id,
                                          recorder_formats, camera_formats )

              $("#customer_ultralink_report_channel_service_grade option:not(:first-child)").remove();
              for (i = 0; i <= data["service_grades"].length - 1; i++) {
                var option_att = data["service_grades"][i][2]
                var option_val = data['service_grades'][i][0]
                $('<option>').attr({
                  'data-is-lower-grade': option_att['data-is-lower-grade'],
                  'data-is-recorder': option_att['data-is-recorder'],
                  'data-is-camera': option_att['data-is-camera'],
                  'data-standard-fcode': option_att['data-standard-fcode'],
                  value: option_val
                }).text(option_val).appendTo('#customer_ultralink_report_channel_service_grade');
              }

              var preferred_vfnn   = $("#customer_ultralink_report_channel_preferred_report_channel_vfnn");
              if (!(preferred_vfnn.length > 0 )) {
                //Make VFNN to place holder on change of SID device type lookup.
                $("#customer_ultralink_report_channel_vfnn").prop('selectedIndex', 0);
                $("#customer_ultralink_report_channel_vfnn").attr("disabled", true);
                $("#customer_ultralink_report_channel_vfnn").trigger('change');
              }

              $("#customer_ultralink_report_channel_service_grade").trigger('change');
              // site_type_filter();

              disable_recorder_sg();
              prepare_service_grades();
              camera_agency_filter($('#site_type').val(), $("#user_request_agency_id option"));
              show_device_type_label(data["device_type"],"customer_ultralink_sid");
              load_company_defaults();
            }
          }
        });
      }
  });
  // Hide site type filter if No self mon linked to dealer.
  // validate_self_mon_exists($("#modal-body-new-user-request #user_request_agency_id option"));
  site_type_filter()
  const user_request_model = "user-request";
  create_sg_feature_list("#customer_ultralink_report_channel_service_grade", "#site_type");

  //resubmit form fields load only recorder values if site type as recorder.
  if ($("#is_recorder").val() == "true") {
    sg_filter_type("1");
  } else {
    sg_filter_type("0");
    restrict_addons_for_core("user_request");
    oh_reporting_input_fields("customer_ultralink_report_channel", "user_request");
  }

  $("#modal-body-new-user-request").on("change", "#site_type", function(){
    if ($(this).val() === "1") {
      $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
      recorder_input_fields();
      sg_filter_type($(this).val());
      $(".sg_features_block").hide();
    } else {
      $("#customer_ultralink_report_channel_service_grade").prop('selectedIndex',0);
      panel_input_fields();
      sg_filter_type($(this).val());
      $(".sg_features_block").show();
    }
    //refresh to agencies to select first value
    $("#user_request_agency_id").prop('selectedIndex',0);
  });
}

var disable_recorder_sg = function (){
  site_type = $("#site_type").val();
  if ((site_type == 1) || (site_type == 2 )) {
    $(".sg_features_block").hide();
  } else {
    ultralink_sg = $("#customer_ultralink_report_channel_service_grade option");
    ultralink_sg.each(function () {
      if ($(this).attr("data-is-recorder") == "true") {
        $(this).hide().prop("disabled", true);
      } else {
        $(this).show().prop("disabled", false);
      }
    });
  }
}

prepare_modal_vars = function(mode) {
  this.modal = "#modal-body-" + mode + "-user-request";
  this.agency_select = $(this.modal + " #user_request_agency_id");
  this.country_select = $(this.modal + " #customer_country_code");
  this.destroy_account = $(this.modal + " #user_request_destroy_account");
  this.vfnn_select = $(this.modal + " #customer_ultralink_report_channel_vfnn");
  this.vfnn_options = $(this.modal + " #customer_ultralink_report_channel_vfnn option");
  this.branch_select = $(this.modal + " #customer_ultralink_report_channel_branch_id");
  this.branch_options = $(this.modal + " #customer_ultralink_report_channel_branch_id option");
  this.format_select = $(this.modal + " #customer_ultralink_report_channel_format");
  this.format_options = $(this.modal + " #customer_ultralink_report_channel_format option");
  this.preferred_format = $(this.modal + " #customer_ultralink_report_channel_preferred_report_channel_format");
  this.new_site_svc_grade_select = $(this.modal + " #customer_ultralink_report_channel_service_grade");
  this.new_site_svc_grade_select_val = $(this.modal + " #customer_ultralink_report_channel_service_grade").val();
  this.new_site_svc_grade_options = $(this.modal + " #customer_ultralink_report_channel_service_grade option");
  this.notification_group = $(modal + " #notification_group_container");
  this.allow_notification = $(modal + " #customer_ultralink_report_channel_notification_group_enabled");
  this.master_unit = $(modal + " .customer_ultralink_report_channel_master_unit");
  this.master_unit_checkbox = $(modal + " #customer_ultralink_report_channel_master_unit");
  this.form = $(modal + " form");
  this.special_vfnn = this.form.data('special-vfnn');
  this.special_vfnn_array = [];
  if (this.special_vfnn) {
    this.special_vfnn_array = ("" + this.special_vfnn).split(",");
  }
  this.device_select = $(this.modal + " #user_request_ultralink_id");
  this.report_channel = $(this.modal + " #user_request_report_channel_id");
  this.new_vfnn_select = $(this.modal + " #report_channel_vfnn");
  this.new_vfnn_options = $(this.modal + " #report_channel_vfnn option");
  this.new_format_select = $(this.modal + " #report_channel_format");
  this.new_format_options = $(this.modal + " #report_channel_format option");
  this.svc_grade_select = $(modal + " #report_channel_service_grade");
  this.svc_grade_select_val = $(modal + " #report_channel_service_grade").val();
  return this.svc_grade_options = $(modal + " #report_channel_service_grade option");
};

load_deactive_user_request = function(mode) {
  no_data_found_check($('#user_request_autocomplete_ultralink'));
  if ($("#user_request_request_type").val() === "4") {
    return $('#user_request_autocomplete_ultralink').on('railsAutocomplete.select', function(event, data) {
      autocomplete_remove_error();
      this.modal = "#modal-body-new-user-request";
      this.destroy_account = $("#modal-body-new-user-request #user_request_destroy_account");
      if (data.item["is-panel-deactive"]) {
        this.destroy_account.prop('checked', true);
        return this.destroy_account.attr('onclick', 'return false');
      } else {
        this.destroy_account.prop('checked', false);
        return this.destroy_account.attr('onclick', 'return true');
      }
    });
  }
};

prepare_svc_grade = function(mode) {
  if (mode === 'edit') {
    return this.svc_grade_select.find('option[value=""]').first().hide().attr("disabled", true);
  }
  if ($("#user_request_request_type").val() === "3") {
    if (mode === 'new') {
      var sg_inputtag = $('#userrequest_sg_tagsinput')
      sg_inputtag.tagsinput({
        maxChars: 12,
        maxTags: 100,
        delimiter: [',', ';']
      });

      sg_inputtag.focus();
      sg_inputtag.on('itemRemoved', function(event) {
        var tagsArray = sg_inputtag.tagsinput('items');
        if(tagsArray.length == 0){
          $('.tagsinput_acct').attr('placeholder', I18n.t('change_sg_label_sidacct', {max_limit: 100}) );
        }
      });

      $(".bootstrap-tagsinput").on('focusout', function () {
        var tagsArray = sg_inputtag.tagsinput('items');
        const req_checkbox = $("#batch_service_request_agree_change_format");
        if(tagsArray.length > 0)
        {
          $("#new-form-submit-user-request").attr("disabled", true);
          $(".ur_service_request_sg").show();
          sg_inputtag.prop('disabled', true);
          $.ajax({
            url: "/user_requests/validate_filter_inputs",
            type: "GET",
            data: {
              batch_service_requests: {
                tagsinput: tagsArray
              },
            },
            success(data, status, xhr) {
              if (!sessionExpired(xhr)) {
                $(".ur_service_request_sg").hide();
                sg_inputtag.prop('disabled', false);
                if(data.invalid_tags.length > 0)
                {

                  $.each(data.invalid_tags, function (index, value) {
                    changeTagColor(value)
                  })
                }
                $('.bootstrap-tagsinput').removeClass('bootstrap_tagsinput_error_msg');
                if( $("#report_channel_service_grade").val() !== "" ){
                  $("#new-form-submit-user-request").attr("disabled", false);
                }
              }
            },
            error: function (xhr, status, error) {
              console.log("ajax failure")
            }
          })
        }else {
          $('.tagsinput_acct').attr('placeholder', I18n.t("change_sg_label_sidacct", {max_limit: 100}) );
        }
      });

      // $("#user_request_autocomplete_report_channel").on("railsAutocomplete.select", function (e, data) {
      //   const is_self_monitoring = data.item["data-is-self-monitoring"];
      //   if (data.item["data-is-recorder"] == true ) {
      //     sg_options = $("#modal-body-new-user-request #report_channel_service_grade option");
      //     sg_options.each(function(){
      //       if ($(this).attr("data-is-recorder") == "true") {
      //         $(this).show().prop("disabled", false);
      //       } else {
      //         $(this).hide().prop("disabled", true);
      //       }
      //     });
      //   }else {
      //     filter_service_grade_by_smonitoring(mode, "user-request", is_self_monitoring, "0", "report_channel")
      //   }
      // })
    }
  }
};

function changeTagColor(tagValue) {
  $('.bootstrap-tagsinput span:contains("'+ tagValue +'")').attr('style', 'background-color: red !important; color: white;');
}

prepare_sg_feature_list = function(mode) {
  if ($("#user_request_request_type").val() === "1") {
    if (this.new_site_svc_grade_select.val() === "") {
      $(this.modal + " .sg_features_block").hide();
    } else {
      $(this.modal + " .sg_features_block").show();
    }
    this.new_site_svc_grade_select.off("change").on('change', (function(_this) {
      return function() {
        var fcode;
        if (_this.new_site_svc_grade_select.val() !== "") {
          $(_this.modal + " .sg_features_block").show();
          fcode = $(_this.modal + " #customer_ultralink_report_channel_service_grade option:selected").attr("data-standard-fcode");
          if ((_this.new_site_svc_grade_select_val === _this.new_site_svc_grade_select.val()) && mode === "edit") {
            fcode = $(_this.modal + " #report_channel_feature_code").val();
          }
          return update_feature_list(mode, fcode);
        } else {
          return $(_this.modal + " .sg_features_block").hide();
        }
      };
    })(this));
    return $(this.modal + " .reset_features").off('click').on('click', function() {
      var fcode;
      fcode = $("#customer_ultralink_report_channel_service_grade option:selected").attr("data-standard-fcode");
      if (fcode === undefined){
        fcode = $("#fcode").val();
      }
      return update_feature_list(mode, fcode);
    });
  } else if ($("#user_request_request_type").val() === "8") {
    if ($('#user_request_autocomplete_report_channel').val() !== "") {
      $("#modal-body-" + mode + "-user-request .sg_features_block").show();
    } else {
      $("#modal-body-" + mode + "-user-request .sg_features_block").hide();
    }
    $('#user_request_autocomplete_report_channel').on('railsAutocomplete.select', function(event, data) {
      if (data.item["data-is-lower-grade"] == true) {
        $( "div[class*='report_channel_video_verification']" ).hide();
        $( "div[class*='report_channel_oh_reporting']" ).hide();
        if (data.item["data-is-self-monitoring"] == true) {
          $( "div[class*='report_channel_oh_reporting']" ).show();
        }
      }
      else
      {
        $( "div[class*='report_channel_video_verification']" ).show();
        $( "div[class*='report_channel_oh_reporting']" ).hide();
      }
      var custom_fcode;
      if (data.item.label === I18n.t('no_results_found')) {
        $("#user_request_autocomplete_report_channel").val("");
        $("#user_request_autocomplete_report_channel").attr("placeholder", I18n.t('autocomplete_message'));
        return false;
      } else {
        autocomplete_remove_error();
        $("#modal-body-" + mode + "-user-request .sg_features_block").show();
        custom_fcode = data.item["custom-fcode"];
        update_feature_list(mode, custom_fcode);
        $('#report_channel_id').data("standard-fcode", data.item["standard-fcode"]);
      }

      // removing the add-ons validation error message
      $('.sg_features_block').find('.has-error').remove();

      // if add-ons are not available, hiding the alert_message and reset_features button
      return hideAddonResetFeatures()
    });
    return $("#modal-body-" + mode + "-user-request .reset_features").off('click').on('click', function() {
      var standard_fcode;
      standard_fcode = $('#report_channel_id').data("standard-fcode");
      return update_feature_list(mode, standard_fcode);
    });
  } else if ($("#user_request_request_type").val() === "7") {
    $('#user_request_autocomplete_report_channel').on('railsAutocomplete.select', function(event, data) {
      $("#devicetype").val(data.item["data-site-type"])
      var recorder_formats = $("#new_user_request").data('recorder-formats');
      var camera_formats = $("#new_user_request").data('camera-formats');
      device_type_formats($("#devicetype").val(), "report_channel_format",
                                  recorder_formats, camera_formats )
    })
  } else {
    if (this.svc_grade_select.val() === "") {
      $("#modal-body-" + mode + "-user-request .sg_features_block").hide();
    }
    this.svc_grade_select.off("change").on('change', (function(_this) {
      return function() {
        var fcode;
        if (_this.svc_grade_select.val() !== "") {
          $("#modal-body-" + mode + "-user-request .sg_features_block").show();
          fcode = $("#modal-body-" + mode + "-user-request #report_channel_service_grade option:selected").attr("data-standard-fcode");
          if ((_this.svc_grade_select_val === _this.svc_grade_select.val()) && mode === "edit") {
            fcode = $("#modal-body-" + mode + "-user-request #report_channel_feature_code").val();
          }
          return update_feature_list(mode, fcode);
        } else {
          return $("#modal-body-" + mode + "-user-request .sg_features_block").hide();
        }
      };
    })(this));
    return $("#modal-body-" + mode + "-user-request .reset_features").off('click').on('click', function() {
      var fcode;
      fcode = $("#modal-body-" + mode + "-user-request #report_channel_service_grade option:selected").attr("data-standard-fcode");
      return update_feature_list(mode, fcode);
    });
  }
};

autocomplete_show_error = function() {
  $('.autocomplete-error').show();
  $('.user_request_autocomplete_ultralink').addClass('has-error');
  $('.user_request_autocomplete_report_channel').addClass('has-error');
  $('.user_request_agency_id').addClass('has-error');
  return false;
};

autocomplete_remove_error = function() {
  $('.autocomplete-error').hide();
  $('.user_request_autocomplete_ultralink').removeClass('has-error');
  $('.user_request_autocomplete_report_channel').removeClass('has-error');
  return $('#new-form-submit-user-request').attr("disabled", false);
};

update_feature_list = function(mode, fcode) {
  var _parent, i, j, ref, results;
  _parent = "#modal-body-" + mode + "-user-request";
  results = [];
  for (i = j = 0, ref = fcode.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
    results.push($(_parent + " #sg_feature_" + i).prop('checked', get_checked_status(fcode[i])));
  }
  return results;
};

get_checked_status = function(bit) {
  if (bit === "1") {
    return true;
  } else {
    return false;
  }
};

set_title = function(action) {
  var title;
  title = $("#request_type_name").val();
  return $("#modal-header-"+action+"-user-request").text(title);
};

change_autocomplete_label = function() {
  var requestType = $("#user_request_request_type").val();
  var ulink_autocomplete_req_types = ["4","5","6","13"];
  var autocomplete_label_id = (ulink_autocomplete_req_types.includes(requestType)) ? "#user_request_autocomplete_ultralink" : "#user_request_autocomplete_report_channel";

  change_autocomplete_placeholder(autocomplete_label_id,
                          I18n.t('autocomplete_message'), I18n.t('sid') + '/' + I18n.t('acctno'))

  if (!["13","1"].includes(requestType) || (requestType === "1" && ($("#form_type").val() == "wizard"))) {
    validateAndSubmit();
  }
};

validateAndSubmit = function() {
  var submit_request = $('#new-form-submit-user-request');
  return submit_request.off('click').on('click', function(e) {
    let ultralink = $('#ultralink_id');
    let report_channel = $('#report_channel_id');
    let agency = $('#user_request_agency_id');
    if ((ultralink && ultralink.val() === "") || (report_channel && report_channel.val() === "") || (agency && agency.val() === "")) {
      autocomplete_show_error();
      return e.preventDefault();
    } else {
      submitByRequestType(e);
    }
  });
}

var submitByRequestType = function(event) {
  let requestType = $("#user_request_request_type").val();

  // Manage Add-on services
  // Verifying the add-on features not available validation in the manage add-on service request
  if (requestType == "8") {
    let allHidden = is_addon_inputs_hidden();
    if (allHidden) {
      if (!($('#sg_features_list').next().hasClass('has-error'))) {
        $('#sg_features_list').after("<div class='has-error'><span class='help-block'>" + I18n.t("no_addon_services_available") + "</span></div>" );
      }
      return event.preventDefault();
    } else {
      return $("#new_user_request").submit();
    }
  } else if (requestType == "3") {
    var sg_inputtag = $('#userrequest_sg_tagsinput');
    var tagsArray = sg_inputtag.tagsinput('items');
    if (tagsArray.length == 0) {
      display_sid_acts_error();
      return event.preventDefault();
    } else if ($("#report_channel_service_grade").val() == "") {
      remove_sid_acts_error();
      $('#report_channel_service_grade').trigger('blur');
    } else {
      return $("#new_user_request").submit();
    }
  } else if (requestType == "1") {
    var site_type      = $('#site_type').val();
    var sg_value       = $("#customer_ultralink_subscription_plan").val();
    var selfMon_check  = $("#user_request_agency_id").find("option:selected").attr("data-is-self-monitoring") == "true";

    // VFNN validation check
    var vfnnValid      = validateReportingFields("customer_ultralink_report_channel_vfnn", true, site_type, sg_value, selfMon_check, false)

    // Acctno validation checking
    var acctValid      = validateReportingFields("customer_ultralink_report_channel_acctno", true, site_type, sg_value, selfMon_check, true)

    // Format validation check
    var formatValid    = validateReportingFields("customer_ultralink_report_channel_format", true, site_type, sg_value, selfMon_check, false)

    if(vfnnValid && formatValid && acctValid){
      return $("#new_user_request").submit();
    } else {
      return false;
    }
  } else if (requestType == "12") {
    sid_input = validate_input($('#user_request_autocomplete_sid'), $('.user_request_autocomplete_sid'),$('#sim_sid') );
    iccid_input = validate_input($('#user_request_autocomplete_sim_iccid'), $('.user_request_autocomplete_sim_iccid'),$('#sim_iccid') );
    sg_input = validate_input($('#user_request_service_grade'), $('.user_request_service_grade'),$('#user_request_service_grade') );
    rp_input = validate_input($('#user_request_report_channel_id'), $('.user_request_report_channel_id'),$('#user_request_report_channel_id') );
    event.preventDefault();
    if(sid_input || iccid_input || sg_input || rp_input) {
      return false;
    } else {
      if($(this).prop('disabled')) {
        console.log('Button is disabled. Request already submitted.');
      } else {
        $(this).addClass('disabled');
        $(this).attr('disabled', 'disabled');
        return $('#new_user_request').submit();
      }
    }
    $("#user_request_autocomplete_sid").on("keypress", function(event) {
      $(".user_request_report_channel_id").hide();
    });
  } else {
    return $('#new_user_request').submit();
  }
}

// Verifying the add-on features not available validation in the manage add-on service request
check_addons_validation = function() {
  if($("#user_request_request_type").val() === "8"){
    validateAndSubmit();
  }
};

// return true if all of the add-on features are hidden; otherwise, it returns false.
var is_addon_inputs_hidden = function () {
  var formGroupDiv = $('#sg_features_list').find('div.form-group')
  var allHidden = true;
  if(formGroupDiv.length > 0 ){
    formGroupDiv.each(function() {
      if ($(this).is(':visible')) {
        allHidden = false;
        return false;
      }
    });
  }
  return allHidden;
}

set_branch = function() {
  var branch_id;
  branch_id = this.agency_select.find("option:selected").attr("data-branch-id");
  return $(this.modal + " #customer_ultralink_report_channel_branch_id").val(branch_id);
};

prepare_country = function() {
  country_select_init("customer", this.modal + " #customer_country_code", this.modal + " #customer_state");
  return this.country_select.trigger('change');
};

no_data_found_check = function(elem) {
  return elem.on('railsAutocomplete.select', function(event, data) {
    if (data.item.label === I18n.t('no_results_found')) {
      elem.val("");
      elem.attr("placeholder", I18n.t('autocomplete_message'));
      return false;
    }
  });
};

prepare_agency = function() {
  var preferred_vfnn   = $("#customer_ultralink_report_channel_preferred_report_channel_vfnn");
  this.agency_select.off('change').on('change', (function(_this) {
    return function() {
      var agency_id, selected_branch, selected_vfnn, is_self_monitoring, site_type;

      agency_id = _this.agency_select.val();
      is_self_monitoring = _this.agency_select.find("option:selected").attr("data-is-self-monitoring");
      var site_type = $("#site_type").val();
      set_branch();
      selected_vfnn = "";
      selected_branch = "";
      _this.vfnn_options.each(function() {
        var vfnn_option;
        vfnn_option = $(this);
        if (vfnn_option.val() === "") {

        } else if (vfnn_option.attr("data-agency-id") === agency_id) {
          if ($(this).prop("selected")) {
            selected_vfnn = $(this).val();
          }
          return vfnn_option.prop("selected", false).show().prop("disabled", false);
        } else {
          return vfnn_option.prop("selected", false).hide().attr("disabled", true);
        }
      });
      if (_this.branch_select.prop('type') !== 'hidden') {
        _this.branch_options.each(function() {
          var branch_option;
          branch_option = $(this);
          if (branch_option.attr("data-agency-id") === agency_id) {
            if ($(this).prop("selected")) {
              selected_branch = $(this).val();
            }
            return branch_option.prop("selected", false).show().prop("disabled", false);
          } else {
            return branch_option.prop("selected", false).hide().attr("disabled", true);
          }
        });
        _this.branch_select.val(selected_branch).trigger('change');
      }
      if (agency_id === "") {
        _this.vfnn_select.attr("readonly", true);
        _this.branch_select.attr("readonly", true);
      } else {
        _this.vfnn_select.attr("readonly", false);
        _this.branch_select.attr("readonly", false);
      }

      let vfnn_option = _this.vfnn_select.find("option[value='"+preferred_vfnn.val()+"']:not([disabled])");

      if(vfnn_option.length > 0){
        _this.vfnn_select.val(preferred_vfnn.val());
        _this.vfnn_select.trigger('change');
      }

      filter_service_grade_by_smonitoring("new", "user-request", is_self_monitoring, site_type, "customer_ultralink_report_channel");
      $("#customer_ultralink_report_channel_vfnn").trigger('change');
    };
  })(this));
  return this.agency_select.trigger('change');
};

prepare_vfnns = function(request_type) {
  return filter_format_by_vfnn(request_type);
};

filter_format_by_vfnn = (function(_this) {
  return function(type) {
    var local_format_options, local_format_select, local_vfnn_options, local_vfnn_select;
    if (type === "change") {
      local_vfnn_select = new_vfnn_select;
      local_vfnn_options = new_vfnn_options;
      local_format_select = new_format_select;
      local_format_options = new_format_options;
      loca_format_id = "report_channel_format"
    } else {
      local_vfnn_select = vfnn_select;
      local_vfnn_options = vfnn_options;
      local_format_select = format_select;
      local_format_options = format_options;
      loca_format_id = "customer_ultralink_report_channel_format";
    }

    var preferred_format = $("#customer_ultralink_report_channel_preferred_report_channel_format");

    local_vfnn_select.off("change").on('change', function() {
      var has_formats, is_master_unit, option, selected_vfnn, vfnn_protocol;
      vfnn_protocol = $(this).find("option:selected").attr("data-protocol");
      if (vfnn_protocol == undefined ) {
        hide_select_options("#customer_ultralink_report_channel_format")
        notification_group.hide();
        allow_notification.prop('checked', false);
        master_unit_checkbox.prop('checked', false);
      }
      else
      {
        has_formats = false;
        is_master_unit = $(this).find("option:selected").attr("data-master-unit");
        selected_vfnn = $(this).find("option:selected").val();
        var site_type = $("#site_type").val();

        local_format_options.slice(1).each(function() {
          const format_option = $(this);
          const option_protocols = format_option.attr("data-protocols");
          if ((option_protocols !== undefined) && (option_protocols.indexOf(vfnn_protocol) >= 0)) {
            has_formats = true;
            return format_option.prop("selected", false).show().prop("disabled", false);
          } else {
            return format_option.prop("selected", false).hide().attr("disabled", true);
          }
        });

        local_format_select.attr("readonly", false);
        if (site_type == 0 && indexOf.call(special_vfnn_array, selected_vfnn) >= 0) {
          notification_group.show();
          if (is_master_unit) {
            master_unit.hide();
            master_unit_checkbox.prop('checked', false);
          } else {
            master_unit.show();
            master_unit_checkbox.prop('checked', false);
          }
        } else {
          notification_group.hide();
          allow_notification.prop('checked', false);
        }

        var recorder_formats = $("#new_user_request").data('recorder-formats');
        var camera_formats = $("#new_user_request").data('camera-formats');

        device_type_formats(site_type, loca_format_id,
                                    recorder_formats, camera_formats )
        preferred_format_val = false;
        if (has_formats) {
          let option = local_format_select.find("option[value='"+preferred_format.val()+"']:not([disabled])");
          if (option.length > 0) {
            preferred_format_val = true;
            local_format_select.val(preferred_format.val());
          }
        } else{
          return local_format_select.trigger('change');
        }
        if (!preferred_format_val) {
          local_format_select.prop('selectedIndex', 0);
        }
      } // Condition ends
    });
  };
})(this);

document_ready = function() {
  var crud_options;
  crud_options = {
    'user-request': {
      buttons: [
        {
          "class": "btn-primary"
        }, {
          "class": "btn-danger"
        }
      ],
      "new": user_request_new_init,
      edit: user_request_edit_init
    }
  };
  init_datatable(crud_options);
  return init_search();
};

refresh = function() {
  return $.get('/user_requests/download_user_request_export.json', function(obj, status, xhr) {
    var download_path;
    if (sessionExpired(xhr)) {
      return;
    }
    download_path = obj.download_path;
    if (download_path !== void 0) {
      show_alert('<a class="user-request-export-download" href="' + download_path + '">' + I18n.t("user_request_export_completed") + '</a>');
      initDownload();
      return;
    }
    if (download_path === void 0) {
      return setTimeout(refresh, 30000);
    }
  });
};

init_datatable = function(crud_options) {
  return dt.initDatatable($('#user-requests-table'), {}, crud_options);
};

init_search = function() {
  return $('.searchtoggle.user_requests').on('on_search_toggled', function() {
    init_advsearch('/user_requests/advfind');
    create_advfind_buttons('user_request');
  });
};

update_approval_button = function() {
  $(document).on("shown.bs.modal", ".modal", function () {
    $(".btn-user-request-btn-primary").off('click').on('click', function() {
      $("#onboarding_customer_request_type").val('');
      const current_company_id = $("#new_onboarding_customer");
      current_company_id
        .find("input, select")
        .each(function () {
          $(this).trigger("blur");
        });
      const form_validators = $(
        ClientSideValidations.selectors.forms[`current_company_id`]
      ).validate();
      if( current_company_id.isValid(form_validators)) {
        $('form#new_onboarding_customer').submit();
        return true;
      } else {
        return false;
      }
    });
  });
}

update_reject_button = function() {
    $(document).on("shown.bs.modal", ".modal", function () {
      $(".btn-user-request-btn-danger").off('click').on('click', function() {
        $("#onboarding_customer_request_type").val("rejected");
        const current_company_id = $("#new_onboarding_customer");
        current_company_id
          .find("input, select")
          .each(function () {
            $(this).trigger("blur");
          });
        const form_validators = $(
          ClientSideValidations.selectors.forms[`current_company_id`]
        ).validate();

        if( current_company_id.isValid(form_validators) && $("#onboarding_customer_comment").val().length  > 0 )
        {
          $('form#new_onboarding_customer').submit();
          return true;
        } else {
          if($("#onboarding_customer_comment").val().length == 0) {
            if($('#onboarding_customer_comment').next().hasClass('has-error') == false) {
              $('#onboarding_customer_comment').parent().addClass(" has-error");
              $('#onboarding_customer_comment').after("<div class='has-error'><span class='help-block'>" + I18n.t("onboarding_customer.rejection_reason") + "</span></div>" );
            }
          }
          return false;
        }
        return false;
      });
    });
};

jQuery(function() {
  run_ontag('user_requests', function() {
    document_ready();
    if (($("#created").length)) {
      setTimeout(refresh, 30000);
    }
    $(document).on('pjax:end', document_ready);
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      $(document).pjax('.pagination a', '[data-pjax-container]');
      return $(document).pjax('#request-filter a', '[data-pjax-container]');
    }
  });
  return jQuery.railsAutocomplete.options = {
    showNoMatches: true,
    noMatchesLabel: I18n.t('no_results_found')
  };
});


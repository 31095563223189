/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
var document_ready,
  get_checked_status,
  init_datatable,
  prepare_agency,
  prepare_channel,
  prepare_modal_vars,
  prepare_sg_feature_list,
  prepare_ultralinks,
  prepare_vfnn,
  report_channel_edit_init,
  report_channel_new_init,
  update_feature_list,
  acctno_validation,
  indexOf =
    [].indexOf ||
    function (item) {
      for (var i = 0, l = this.length; i < l; i++) {
        if (i in this && this[i] === item) return i;
      }
      return -1;
    };

document_ready = function () {
  var crud_options;
  crud_options = {
    "report-channel": {
      new: report_channel_new_init,
      edit: report_channel_edit_init,
    },
  };
  return init_datatable(crud_options);
};

report_channel_new_init = function () {
  var mode;
  mode = "new";
  prepare_modal_vars(mode);
  prepare_agency(mode);
  prepare_sg_feature_list(mode);
  prepare_ultralinks(mode);
  oh_reporting_input_fields("report_channel");
  acctno_validation(mode);
  reset_vfnn_on_empty_branch(mode);
  return prepare_vfnn(mode);
};

report_channel_edit_init = function () {
  var mode;
  mode = "edit";
  prepare_modal_vars(mode);
  prepare_agency(mode);
  prepare_channel(mode);
  prepare_vfnn(mode);
  oh_reporting_input_fields("report_channel");
  acctno_validation(mode);
  $("#report_channel_format").blur();
  set_vfnn_for_edit(mode);

  // Adding report_channel_id as a filter to the dealer autocomplete path.
  $('#report_channel_branch_id_autocomplete').on('focus', function() {
    const site_type = $("#site_type").val() || "0";
    const report_channel_id = $("#report_channel_id").val();
    const baseUrl   = $(this).data('url') || $(this).attr('data-url');
    const newUrl    = baseUrl.split('?')[0] + '?device_type=' + encodeURIComponent(site_type) + '&report_channel_id=' + encodeURIComponent(report_channel_id);

    // Reinitialize the autocomplete with the new URL
    $(this).autocomplete('option', 'source', newUrl);
  });
  reset_vfnn_on_empty_branch(mode);

  return prepare_sg_feature_list(mode);
};

// Function to initialize account number validation based on mode
acctno_validation = function(mode){
  var formIdPrefix, acctnoInputAttr, submitButtonSelector;

  // Set parameters based on the mode
  if (mode === "new") {
    formIdPrefix = 'new_report_channel';
    acctnoInputAttr = 'report_channel_acctno';
    submitButtonSelector = '#new-form-submit-report-channel';
  } else {
    formIdPrefix = 'edit_report_channel_';
    acctnoInputAttr = 'report_channel_acctno';
    submitButtonSelector = '#edit-form-submit-report-channel';
  }

  // Calling the initAcctnoValidation method
  initAcctnoValidation(formIdPrefix, acctnoInputAttr, submitButtonSelector);
}

prepare_modal_vars = function (mode) {
  var _modal;
  _modal = "#modal-body-" + mode + "-report-channel";
  this.agency_select = $(_modal + " #report_channel_branch_id_autocomplete");
  this.vfnn_select = $(_modal + " #report_channel_vfnn");
  this.vfnn_options = $(_modal + " #report_channel_vfnn option");
  this.ultralink_select = $(_modal + " #report_channel_ultralink_id");
  this.ultralink_options = $(_modal + " #report_channel_ultralink_id option");
  this.channel_select = $(_modal + " #report_channel_channel");
  this.channel_options = $(_modal + " #report_channel_channel option");
  this.format_select = $(_modal + " #report_channel_format");
  this.format_options = $(_modal + " #report_channel_format option");
  this.svc_grade_select = $(_modal + " #report_channel_service_grade");
  this.svc_grade_select_val = $(
    _modal + " #report_channel_service_grade"
  ).val();
  this.svc_grade_options = $(_modal + " #report_channel_service_grade option");
  this.form = $(_modal + " form");
  this.init_format_select = this.format_select.val();
  this.notification_group = $(_modal + " #notification_group_container");
  this.allow_notification = $(
    _modal + " #report_channel_notification_group_enabled"
  );
  this.master_unit = $(_modal + " .report_channel_master_unit");
  this.master_unit_checkbox = $(_modal + " #report_channel_master_unit");
  this.special_vfnn = this.form.data("special-vfnn");
  this.special_vfnn_array = [];
  if (this.special_vfnn) {
    this.special_vfnn_array = ("" + this.special_vfnn).split(",");
  }
  return (this.default_format = this.form.data("default-format"));
};

prepare_agency = function (mode) {
  if ($(".report_channel_service_grade").find(':selected').attr("data-is-lower-grade") == "true") {
    $( "div[class*='report_channel_video_verification']" ).hide();
  }

  var agency_id = $("#report_channel_agency_id").val(agency_id);

  if (mode === "new") {
    this.vfnn_options.each(function () {
      var vfnn_option = $(this);
      var option_agency_id = Number(vfnn_option.attr("data-agency-id"));
      if (vfnn_option.val() !== '') {
        if (option_agency_id === Number(agency_id)) {
          vfnn_option.prop("selected", false).show().prop("disabled", false);
          has_vfnn = true;
        } else {
          vfnn_option.prop("selected", false).hide().attr("disabled", true);
        }
      }
    });
  }

  if (mode === "edit") {
    var branch_autocomplete = $("#modal-body-edit-report-channel #report_channel_branch_id_autocomplete");
    var branch_name = $('#modal-body-edit-report-channel #branch_name').val();
    if (branch_name) {
      $('div.report_channel_branch_id_autocomplete').addClass("focused")
    } else {
      branch_name = I18n.t('no_branch').toString();
      $('div.report_channel_branch_id_autocomplete').removeClass("focused")
    }
    branch_autocomplete.attr('value', branch_name)
  }

  this.agency_select.on('railsAutocomplete.select', (function(_this) {
    return function (event, data) {
      var agency_id = data.item["data-agency-id"];
      var branch_id = data.item["id"];
      var is_self_monitoring = data.item["data-is-self-monitoring"] !== undefined ? data.item["data-is-self-monitoring"] : false;
      $("#is_self_monitoring").val(is_self_monitoring)
      var report_channel_vfnn, report_channel_sgrade, has_vfnn = false, has_default = false;
      var site_type = $("#site_type").length > 0 ? $("#site_type").val() : "0";
      $("#report_channel_branch_id").val(branch_id);

      if (mode === "edit") {
        report_channel_vfnn   = _this.form.data("report-channel-vfnn").toString();
        report_channel_sgrade = _this.form.data("report-channel-sgrade").toString();
      }

      _this.vfnn_options.each(function () {
        var vfnn_option = $(this);
        var option_agency_id = Number(vfnn_option.attr("data-agency-id"));
        if (vfnn_option.val() !== '') {
          if (option_agency_id === Number(agency_id)) {
            vfnn_option.prop("selected", false).show().prop("disabled", false);
            has_vfnn = true;
          } else {
            vfnn_option.prop("selected", false).hide().attr("disabled", true);
          }

          if (vfnn_option.val().toString() === report_channel_vfnn) {
            if (vfnn_option.attr("disabled") !== "disabled") {
              return (has_default = true);
            }
          }
        }
      });

      if (agency_id) {
        _this.vfnn_select.attr("readonly", false);
      } else {
        _this.vfnn_select.attr("readonly", true);
      }

      if (!has_vfnn) {
        _this.vfnn_select
          .find("option")
          .first()
          .show()
          .prop("disabled", false);
      }

      if (mode === "new") {
        _this.vfnn_select
          .find("option:not([disabled])")
          .first()
          .prop("selected", true);
      } else {
        if (has_default) {
          _this.vfnn_select
            .find(
              "option:not([disabled]):contains(" + report_channel_vfnn + ")"
            )
            .first()
            .prop("selected", true);
        } else {
          _this.vfnn_select
            .find("option")
            .first()
            .show()
            .prop("disabled", false)
            .prop("selected", true);
        }
      }

      _this.vfnn_select.trigger("change");

      filter_service_grade_by_smonitoring(mode, "report-channel", is_self_monitoring, site_type, "report_channel")

      if (mode == "edit") {
        _this.svc_grade_select
          .find(
            "option:not([disabled]):contains(" + report_channel_sgrade + ")"
          )
          .first()
          .prop("selected", true);
        $("#report_channel_service_grade").trigger('change');
        _this.vfnn_select.enableClientSideValidations();
        _this.format_select.enableClientSideValidations();
      }

      manage_add_ons(is_self_monitoring);
    };
  })(this));

  return this.agency_select.trigger("change");
};

// Reset VFNN if dealer autocomplete is cleared manually
var reset_vfnn_on_empty_branch = function(mode) {
  var _modal = "#modal-body-" + mode + "-report-channel";

  // Attach event to the modal container to ensure it works even if the modal is re-opened or re-rendered
  $(_modal).on('input', '#report_channel_branch_id_autocomplete', function() {
    if ($(this).val().trim() === "") {
      $(_modal + " #report_channel_vfnn").prop('selectedIndex', 0).trigger("change");
      $(_modal + " #report_channel_branch_id").val('');
    }
  });
};

// Loading associated VFNNS for the first time in Report channel edit form
var set_vfnn_for_edit = function(mode) {
  var agencyId = Number($("#agency_id").val());

  if (agencyId) {
    var vfnnSelect = $("#modal-body-" + mode + "-report-channel #report_channel_vfnn");
    var selectedValue = vfnnSelect.val();

    vfnnSelect.find("option").each(function () {
      var optionElement = $(this);

      if (optionElement.val()) {
        var optionAgencyId = Number(optionElement.data("agency-id"));

        var isVisible = optionAgencyId === agencyId;
        optionElement.prop("disabled", !isVisible).toggle(isVisible);
      }
    });

    vfnnSelect.val(selectedValue);
  }
}

manage_add_ons = function (is_self_monitoring) {
  if (is_self_monitoring != undefined) {
    let vv_add_on, oh_add_on;
    $( "div[class*='report_channel_video_verification']" ).show();
    oh_add_on = $("div[class*='report_channel_oh_reporting']");
    oh_chechbox = $("input[name*='[oh_reporting']");
    if($(".report_channel_service_grade").find(':selected').attr("data-is-lower-grade") == "true"){
      if(is_self_monitoring.toString() == "true"){
        oh_add_on.show();
      }
      else {
        oh_chechbox.prop('checked', false);
        oh_add_on.hide();
      }
    } else {
      oh_chechbox.prop('checked', false);
      oh_add_on.hide();
    }
    oh_add_on.trigger('change');
  }
};

prepare_ultralinks = function (mode) {
  this.ultralink_select.off("change").on(
    "change",
    (function (_this) {
      return function () {
        return prepare_channel(mode);
      };
    })(this)
  );
  return this.ultralink_select.trigger("change");
};

prepare_channel = function (mode) {
  var available_ultralink_id,
    channel_select,
    report_channel_channel,
    ultralink_id;
  ultralink_id = this.ultralink_select.find("option:selected").val();
  channel_select = this.channel_select;
  available_ultralink_id = ultralink_id + "_available";
  if (mode === "edit") {
    report_channel_channel = this.form
      .data("report-channel-channel")
      .toString();
  }
  this.channel_options.each(function (index, val) {
    var option_channel, option_ultralink_id;
    option_ultralink_id = $(this).data("ultralink-id").toString();
    option_channel = $(this).data("channel").toString();
    if (option_ultralink_id === ultralink_id) {
      if (report_channel_channel === option_channel) {
        return $(this).show().prop("disabled", false);
      } else {
        return $(this).show().attr("disabled", true);
      }
    } else if (option_ultralink_id === available_ultralink_id) {
      return $(this).show().prop("disabled", false);
    } else {
      return $(this).hide().attr("disabled", true);
    }
  });
  if (ultralink_id) {
    channel_select.attr("readonly", false);
  } else {
    channel_select.attr("readonly", true);
  }
  if (mode === "new") {
    this.channel_select
      .find("option:not([disabled])")
      .first()
      .prop("selected", true);
  } else {
    this.channel_select
      .find("option:not([disabled]):contains(" + report_channel_channel + ")")
      .first()
      .prop("selected", true);
  }
  return this.channel_select.trigger("change");
};

prepare_vfnn = function (mode) {
  var report_channel_format, report_channel_vfnn;
  if (mode === "edit") {
    report_channel_vfnn = this.form.data("report-channel-vfnn").toString();
  }
  var recorder_formats = this.form.data('recorder-formats');
  var camera_formats = this.form.data('camera-formats');
  report_channel_format = this.form.data("report-channel-format").toString();
  if (report_channel_format === "system") {
    report_channel_format = default_format;
  }

  this.vfnn_select.off("change").on(
    "change",
    (function (_this) {
      return function () {
        var master_unit, selected_vfnn, ultralink_id, vfnn_protocol;
        vfnn_protocol = _this.vfnn_select
          .find("option:selected")
          .attr("data-protocol");
        master_unit = _this.vfnn_select
          .find("option:selected")
          .attr("data-master-unit");
        ultralink_id = _this.ultralink_select.find("option:selected").val();
        selected_vfnn = _this.vfnn_select.find("option:selected").val();
        _this.format_options.each(function () {
          var format_option;
          format_option = $(this);
          if (format_option.val() !== '') {
            if (format_option.attr("data-protocols") === vfnn_protocol) {
              return format_option
                .prop("selected", false)
                .show()
                .prop("disabled", false);
            } else {
              return format_option
                .prop("selected", false)
                .hide()
                .attr("disabled", true);
            }
          }
        });

        if (vfnn_protocol) {
          _this.format_select.attr("readonly", false);
          if (indexOf.call(_this.special_vfnn_array, selected_vfnn) >= 0) {
            _this.notification_group.show();
            if (master_unit) {
              if (master_unit === ultralink_id) {
                _this.master_unit.show();
                _this.master_unit_checkbox.prop("checked", true);
              } else {
                _this.master_unit.hide();
                _this.master_unit_checkbox.prop("checked", false);
              }
            } else {
              _this.master_unit.show();
              _this.master_unit_checkbox.prop("checked", false);
            }
          } else {
            _this.notification_group.hide();
            _this.allow_notification.prop("checked", false);
            _this.master_unit_checkbox.prop("checked", false);
          }
        } else {
          _this.format_select.attr("readonly", true);
          _this.notification_group.hide();
          _this.allow_notification.prop("checked", false);
          _this.master_unit_checkbox.prop("checked", false);
        }
        var site_type = $("#site_type").val();

        if (mode === "new") {
          _this.format_select
            .find(
              "option[value='" + report_channel_format + "']:not([disabled])"
            )
            .first()
            .prop("selected", true);
        } else {
          if (report_channel_vfnn !== _this.vfnn_select.val()) {
            _this.format_select
              .find("option")
              .first()
              .show()
              .prop("disabled", false)
              .prop("selected", true);
          }
        }

        if (site_type == '1' || site_type == '2'){
          format_type = (site_type == '1') ? recorder_formats.split(',') : camera_formats.split(',')
          $("#report_channel_format option").each(function() {
            var displayStyle = $(this).css('display');
            if (displayStyle !== 'none') {
              if(Array.from(format_type).includes($(this).val())  || $(this).val() == "")
              {
                $(this).show().prop("disabled", false);
              } else
              {
                $(this).hide().prop("disabled", true);
              }
            }
          })
        } else {
          $("#report_channel_format option").each(function() {
            var displayStyle = $(this).css('display');
            if (displayStyle !== 'none') {
              $(this).show().prop("disabled", false);
            }
          })
        }

        return _this.format_select.trigger("change");
      };
    })(this)
  );

  this.vfnn_select.trigger("change");
  if (mode === "edit") {
    return this.format_select
      .find("option[value='" + report_channel_format + "']:not([disabled])")
      .first()
      .prop("selected", true);
  }


};

prepare_sg_feature_list = function (mode) {
  if (this.svc_grade_select.val() === "") {
    $("#modal-body-" + mode + "-report-channel .sg_features_block").hide();
  }
  this.svc_grade_select.off("change").on(
    "change",
    (function (_this) {
      return function () {
        var fcode, is_self_monitoring;
        is_self_monitoring = $("#is_self_monitoring").val();
        manage_add_ons(is_self_monitoring);
        if (_this.svc_grade_select.val() !== "") {
          //Hide Video Verification for lower service grade(core)
          $( "div[class*='report_channel_video_verification']" ).show();
          $(".report_channel_service_grade").off("change").on('change', function() {
            if($(this).find(':selected').attr("data-is-lower-grade") == "true"){
              $( "div[class*='report_channel_video_verification']" ).hide();
              if ($(this).find(':selected').attr("data-is-recorder") == "true") {
                $(".report_channel_vfnn").hide();
                $(".report_channel_acctno").hide();
                $(".report_channel_format").hide();
              }
              else
              {
                $(".report_channel_vfnn").show();
                $(".report_channel_acctno").show();
                $(".report_channel_format").show();
              }
            } else {
              $( "div[class*='report_channel_video_verification']" ).show();
              $(".report_channel_vfnn").show();
              $(".report_channel_acctno").show();
              $(".report_channel_format").show();
            }
          });

          $(
            "#modal-body-" + mode + "-report-channel .sg_features_block"
          ).show();
          fcode = $(
            "#modal-body-" +
              mode +
              "-report-channel #report_channel_service_grade option:selected"
          ).attr("data-standard-fcode");
          if (
            _this.svc_grade_select_val === _this.svc_grade_select.val() &&
            mode === "edit"
          ) {
            fcode = $(
              "#modal-body-" +
                mode +
                "-report-channel #report_channel_feature_code"
            ).val();
          }
          return update_feature_list(mode, fcode);
        } else {
          return $(
            "#modal-body-" + mode + "-report-channel .sg_features_block"
          ).hide();
        }
      };
    })(this)
  );
  this.svc_grade_select.trigger('change');
  $("div[class*='report_channel_oh_reporting']").trigger("change");
  return $("#modal-body-" + mode + "-report-channel .reset_features")
    .off("click")
    .on("click", function () {
      var fcode;
      fcode = $(
        "#modal-body-" +
          mode +
          "-report-channel #report_channel_service_grade option:selected"
      ).attr("data-standard-fcode");
      $("#report_channel_service_grade").trigger('change');
      return update_feature_list(mode, fcode);
    });
};

update_feature_list = function (mode, fcode) {
  var _parent, i, j, ref, results;
  results = [];
  if (fcode === null || fcode === undefined)
  {
    return results;
  }
  _parent = "#modal-body-" + mode + "-report-channel";
  for (
    i = j = 0, ref = fcode.length;
    0 <= ref ? j < ref : j > ref;
    i = 0 <= ref ? ++j : --j
  ) {
    results.push(
      $(_parent + " #sg_feature_" + i).prop(
        "checked",
        get_checked_status(fcode[i])
      )
    );
  }
  return results;
};

get_checked_status = function (bit) {
  if (bit === "1") {
    return true;
  } else {
    return false;
  }
};

init_datatable = function (crud_options) {
  return dt.initDatatable($("#report-channels-table"), {}, crud_options);
};

jQuery(function () {
  return run_ontag("report_channels", function () {
    return document_ready();
  });
});

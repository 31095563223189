/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const initDownload = () =>
  $("a.company-export-download")
    .off("click")
    .on("click", function () {
      $.fileDownload($(this).attr("href"), {
        data: { format: "xlsx" },
        successCallback(url) {
          show_alert(I18n.t("company_download_successful"));
          return enable_refresh();
        },
        failCallback(url) {
          return show_error(I18n.t("company_download_failed"));
        },
      });
      return false;
    });

const company_delete_init = function (button) {
  if (button.attr("having-child-company") === "true") {
    $("#default-notice").empty();
    return $("#default-notice").append(I18n.t("delete_child_companies"));
  } else {
    $("#default-notice").empty();
    return $("#default-notice").append(
      I18n.t("delete_notice", { model_name: "company" })
    );
  }
};

var enable_refresh = function () {
  if ($("#message-area a.close").attr("data-refresh") !== "enabled") {
    $("#message-area a.close").attr("data-refresh", "enabled");
    return $("#message-area a.close").on("click", () =>
      setTimeout(refresh, 30000)
    );
  }
};

const sales_region_change_init = (modal) =>
  $(modal + " #company_sales_region")
    .off("change")
    .on("change", function () {
      filter_company_by_sales_region(
        $(this).val(),
        modal + " #company_company_default_agency_id"
      );
      toggle_oracle_entity_field(modal);
      if (
        ($(this).val() === "EU" || $(this).val() === "US") &&
        ($(modal + " #company_company_type").val() !== "1" && $(modal + " #company_company_type").val() !== "4")
      ) {
        $(".company_oracle_number").show();
        return $("#company_oracle_number").enableClientSideValidations();
      } else {
        $("#company_oracle_number").disableClientSideValidations();
        $("#company_oracle_number").parent().removeClass("has-error");
        $("#company_oracle_number").next("span.help-block").remove();
        $("#company_oracle_number").val("");
        return $(".company_oracle_number").hide();
      }
    });

const client_side_validation_init = function () {
  window.ClientSideValidations.callbacks.element.before = function (
    element,
    eventData
  ) {
    if (element.attr("id") === "company_name" && element.val() !== "") {
      let prefix;
      if ((prefix = element.attr("data-company-prefix"))) {
        $(".company_name_placeholder").val(element.val());
        $(".company_name_placeholder").show();
        element.parent().hide();
        return element.val(prefix + "\x1E" + element.val());
      }
    }
  };
  return (window.ClientSideValidations.callbacks.element.after = function (
    element,
    eventData
  ) {
    if (element.attr("id") === "company_name" && element.val() !== "") {
      let prefix;
      if ((prefix = element.attr("data-company-prefix"))) {
        $(".company_name_placeholder").hide();
        element.val(element.val().replace(prefix + "\x1E", ""));
        return element.parent().show();
      }
    }
  });
};

const company_type_change_init = (modal) =>
  $(modal + " #company_company_type")
    .off("change")
    .on("change", function () {
      return toggle_company_fields($(this).val(), modal);
    });

var toggle_oracle_entity_field = function (modal) {
  if ($(modal + " #oracle_entity_select").length > 0)
  {
    if ($(modal + " #company_sales_region").val() === "EU")
    {
       $(modal +" #oracle_entity_text").val("");
       $(modal +" #oracle_entity_text").parent().hide();
       $(modal +" #oracle_entity_text").prop("disabled", true);
       $(modal +" #oracle_entity_select").prop("disabled", false);
       $(modal +" #oracle_entity_select").parent().show();
    }
    else
    {
       $(modal +" #oracle_entity_select").val("");
       $(modal +" #oracle_entity_select").parent().hide();
       $(modal +" #oracle_entity_select").prop("disabled", true);
       $(modal +" #oracle_entity_text").prop("disabled", false);
       $(modal +" #oracle_entity_text").parent().show();
       $(modal +" #oracle_entity_text").trigger('change');
    }
  }
}

var toggle_company_fields = function (comp_type, modal) {
  const all_agencies = $("#company_link_all_agencies").closest(".checkbox");
  all_agencies.hide();
  const auto_link_cs = $("#company_auto_link_agencies").closest(".checkbox");
  auto_link_cs.hide();
  $(".company_oracle_number").hide();
  $(modal + " #approval_uncheck_warning").hide();
  $(modal + " .company_regional_distributor_link_country_code").hide();
  if (comp_type === "1") {
    $(modal + " .company_is_demo").show();
    $(modal + " .company_agency_approval").show();
    $(modal + " #company_branding_tab").hide();
    $(modal + " #event_threshold_tab").hide();
    $(modal + " #company_tab").show();

    $(".abbrev").prop("disabled", false);
    $(".company_abbrev").show();

    //Reset Values
    $("#company_company_default_agency_id").val("");
    $("#company_company_default_vfnn").val("");
    $(".company_company_default_agency_id").hide();
    $(".company_company_default_vfnn").hide();
    $(".alarm_format").attr("disabled", true);
    $(".company_company_default_alarm_format").hide();
    $("#company_company_default_alarm_format").val("");
    $("#company_oracle_number").val("");
  } else if (comp_type === "2") {
    $(modal + " .company_is_demo").show();
    $(modal + " .company_agency_approval").hide();
    $(modal + " #company_branding_tab").show();
    $(modal + " #event_threshold_tab").show();

    $(".agency_id").prop("disabled", false);
    $(".company_company_default_agency_id").show();

    $(".alarm_format").prop("disabled", false);
    $(".company_company_default_alarm_format").show();

    $(".abbrev").attr("disabled", true);
    $(".company_abbrev").hide();

    $("#company_abbrev").val("");

    if ($("#company_sales_region").val() === "") {
      reset_company_selection("#company_company_default_agency_id");
    } else {
      $("#company_company_default_agency_id").attr("readonly", false);
    }

    all_agencies.show();
    auto_link_cs.show();
  } else {
    $(modal + " .company_is_demo").show();
    $(modal + " .company_agency_approval").hide();
    $(modal + " #company_branding_tab").hide();
    $(modal + " #event_threshold_tab").hide();
    $(modal + " #company_tab").show();

    $(".abbrev").attr("disabled", true);
    $(".company_abbrev").hide();
    $("#company_abbrev").val("");

    $(".agency_id").attr("disabled", true);
    $(".company_company_default_agency_id").hide();
    $("#company_company_default_agency_id").val("");

    $(".company_company_default_vfnn").hide();

    $(".alarm_format").attr("disabled", true);
    $(".company_company_default_alarm_format").hide();
    $("#company_company_default_alarm_format").val("");
    all_agencies.show();
    auto_link_cs.show();
    if (comp_type == "4") {
      $(modal + " .company_regional_distributor_link_country_code").show();
      $(".company_oracle_number").hide();
      $(".company_oracle_entity").hide();
      $("#company_oracle_number").val("");
      $("#company_oracle_entity").val("");
    }
  }
  if ((comp_type !== "1") && (comp_type !== "4") ) {
    if (
      $("#company_sales_region").val() === "EU" ||
      $("#company_sales_region").val() === "US"
    ) {
      $(".company_oracle_number").show();
      return $("#company_oracle_number").enableClientSideValidations();
    } else {
      $("#company_oracle_number").disableClientSideValidations();
      $("#company_oracle_number").parent().removeClass("has-error");
      $("#company_oracle_number").next("span.help-block").remove();
      return $("#company_oracle_number").val("");
    }
  }
  toggle_oracle_entity_field(modal);
};

var refresh = () =>
  $.get("/companies/download_company_export.json", function (obj, status, xhr) {
    if (sessionExpired(xhr)) {
      return;
    }
    const { download_path } = obj;
    if (download_path !== undefined) {
      show_alert(
        '<a class="company-export-download" href="' +
          download_path +
          '">' +
          I18n.t("company_export_completed") +
          "</a>"
      );
      initDownload();
      return;
    }

    if (download_path === undefined) {
      return setTimeout(refresh, 30000);
    }
  });

const distributor_link_form_new_init = function () {
  $("#distributor_link_distributor_id").on("focusout", function () {
    const select_id = $(
      ClientSideValidations.selectors.forms["#new_distributor_link"]
    ).validate();
    $("input:checkbox").attr("disabled", false);
    if (!$("#new_distributor_link").isValid(select_id)) {
      $("input:checkbox").prop("checked", false);
      return $("input:checkbox").attr("disabled", true);
    }
  });
  return $("#distributor_link_link_all_agencies").on("change", function () {
    $("#distributor_link_link_agencies_to_dealers").prop("checked", false);
    return $("#link_agencies_to_dealers").toggle();
  });
};

const company_form_new_init = function () {
  company_branding_logo_init("new");
  company_tab_fields_valid("#modal-body-new-company");
  company_branding_tab_fields_valid("#modal-body-new-company");
  company_eventthreshold_tab("#modal-body-new-company");
  country_select_init(
    "company",
    "#modal-body-new-company #company_country_code",
    "#modal-body-new-company #company_state"
  );
  $("#modal-body-new-company #company_country_code").trigger("change");
  client_side_validation_init;

  company_type_change_init("#modal-body-new-company");
  $("#modal-body-new-company #company_company_type").trigger("change");

  sales_region_change_init("#modal-body-new-company");
  $("#modal-body-new-company #company_sales_region").trigger("change");

  return agency_vfnn_select_init(
    "company",
    "#modal-body-new-company #company_company_default_agency_id",
    "#modal-body-new-company #company_company_default_vfnn",
    "#company_company_default_agency_id",
    "#company_company_default_vfnn"
  );
};

const company_form_edit_init = function () {
  company_branding_logo_init("edit");

  company_tab_fields_valid("#modal-body-edit-company");
  company_branding_tab_fields_valid("#modal-body-edit-company");
  company_eventthreshold_tab("#modal-body-edit-company");
  country_select_init(
    "company",
    "#modal-body-edit-company #company_country_code",
    "#modal-body-edit-company #company_state"
  );
  $("#modal-body-edit-company #company_country_code").trigger("change");
  client_side_validation_init;

  company_type_change_init("#modal-body-edit-company");
  $("#modal-body-edit-company #company_company_type").trigger("change");

  sales_region_change_init("#modal-body-edit-company");
  $("#modal-body-edit-company #company_sales_region").trigger("change");

  agency_vfnn_select_init(
    "company",
    "#modal-body-edit-company #company_company_default_agency_id",
    "#modal-body-edit-company #company_company_default_vfnn",
    "#company_company_default_agency_id",
    "#company_company_default_vfnn"
  );
  initToolTip();
  toggle_agency_approval("#modal-body-edit-company");
  $("#remove_branding_logo")
    .off("click")
    .on("click", () => $("#company_branding_logo").remove());
  return $("#remove_touchscreen_logo")
    .off("click")
    .on("click", () => $("#company_touchscreen_logo").remove());
};

var toggle_agency_approval = (modal) =>
  $("body").on("change", "#company_agency_approval", function () {
    if ($(this).prop("checked") === false) {
      return $(modal + " #approval_uncheck_warning").show();
    } else if ($(this).prop("checked") === true) {
      return $(modal + " #approval_uncheck_warning").hide();
    }
  });

var company_tab_fields_valid = (model) =>
  $(model + " #company_tab").on("click", function () {
    if ($(model + " #company_tab").attr("class") === "active") {
      return false;
    } else {
      if (form_validation_before_toggle(model)) {
        $(model + " #company_tab").addClass("active");
        $(model + " #companies_details").show();
        $(model + " #company_branding_tab").removeClass("active");
        $(model + " #company_branding_details").hide();
        $(model + " #event_threshold_tab").removeClass("active");
        $(model + " #event_categories_details").hide();
        return $(model + " #company_event_categories").hide();
      }
    }
  });

var company_branding_tab_fields_valid = (model) =>
  $(model + " #company_branding_tab").on("click", function () {
    if ($(model + " #company_branding_tab").attr("class") === "active") {
      return false;
    } else {
      if (form_validation_before_toggle(model)) {
        if (
          $("#company_company_branding_reuse_branding_logo").attr("checked") ===
          "checked"
        ) {
          $("#touchscreen_logo").hide();
        }
        $(model + " #company_tab").removeClass("active");
        $(model + " #companies_details").hide();
        $(model + " #company_branding_details").show();
        $(model + " #company_branding_tab").addClass("active");
        $(model + " #company_event_categories").hide();
        $(model + " #event_categories_details").hide();
        $(model + " #event_threshold_tab").removeClass("active");
        return $(
          model + " #company_company_branding_email"
        ).enableClientSideValidations();
      }
    }
  });

var company_eventthreshold_tab = (model) =>
  $(model + " #event_threshold_tab").on("click", function () {
    if ($(model + " #event_threshold_tab").attr("class") === "active") {
      return false;
    } else {
      if (form_validation_before_toggle(model)) {
        $(model + " #company_branding_details").hide();
        $(model + " #company_event_categories").show();
        $(model + " #event_categories_details").show();
        $(model + " #companies_details").hide();
        $(model + " #event_threshold_tab").addClass("active");
        $(model + " #company_tab").removeClass("active");
        $(model + " #company_branding_tab").removeClass("active");
        $(
          model + " #company_ultralink_event_correlation_threshold_threshold_day"
        ).enableClientSideValidations();
        $(
          model + " #company_ultralink_event_correlation_threshold_threshold_week"
        ).enableClientSideValidations();
        return $(
          model + " #company_ultralink_event_correlation_threshold_threshold_month"
        ).enableClientSideValidations();
      }
    }
  });

var company_branding_logo_init = function (mode) {
  $("body").on(
    "change",
    "#company_company_branding_branding_logo",
    function () {
      return validate_logo_type_and_size(
        this.files[0],
        "company_company_branding_branding_logo"
      );
    }
  );

  $("body").on(
    "change",
    "#company_company_branding_touchscreen_logo",
    function () {
      return validate_logo_type_and_size(
        this.files[0],
        "company_company_branding_touchscreen_logo"
      );
    }
  );

  return $("#company_company_branding_reuse_branding_logo").on(
    "change",
    function () {
      const checked = $(this).attr("checked");
      if (checked || checked === "undefined") {
        $(this).attr("checked", false);
        return $("#touchscreen_logo").show();
      } else {
        $(this).attr("checked", true);
        return $("#touchscreen_logo").hide();
      }
    }
  );
};

var validate_logo_type_and_size = function (file_ref, dom_ref) {
  let file_ext = file_ref.name.split(".").pop();
  file_ext = file_ext.toLowerCase();
  $("input#" + dom_ref)
    .next("div.has-error")
    .remove();
  const supported_image_type = ["jpg", "jpeg", "png"];
  if (!Array.from(supported_image_type).includes(file_ext)) {
    return $("input#" + dom_ref).after(
      "<div class='has-error'><span class='help-block'>" +
        I18n.t("invalid_avatar_type", { type: ".jpg / .jpeg / .png" }) +
        "</span></div>"
    );
  } else if (file_ref.size > 1 * 1024 * 1024) {
    return $("input#" + dom_ref).after(
      "<div class='has-error'><span class='help-block'>" +
        I18n.t("company_branding_file_size_error", { size: "1 MB" }) +
        "</span></div>"
    );
  } else {
    return $("input#" + dom_ref)
      .next("div.has-error")
      .remove();
  }
};

var form_validation_before_toggle = function (model) {
  const current_company_id = $(model + " #company_details")
    .find("form")
    .attr("id");
  if (current_company_id === "new_company") {
    $("#new_company")
      .find("input, select")
      .each(function () {
        $(this).trigger("blur");
      });
  }
  const form_validators = $(
    ClientSideValidations.selectors.forms[`${current_company_id}`]
  ).validate();
  return $(`#${current_company_id}`).isValid(form_validators);
};

var agency_vfnn_select_init = function (
  model,
  modal_agency_id,
  modal_vfnn,
  select_agency_id,
  select_vfnn
) {
  $(modal_agency_id)
    .off("change")
    .on("change", function () {
      const agency_id = $(modal_agency_id + " " + "option:selected").attr(
        "data-agency-id"
      );
      $(select_agency_id).val(agency_id);
      filter_vfnn_by_agency_id(agency_id, modal_vfnn);
      if (agency_id === undefined) {
        $(".company_company_default_vfnn").hide();
        $(select_vfnn).disableClientSideValidations();
        $(select_vfnn).parent().removeClass("has-error");
        return $(select_vfnn).next("span.help-block").remove();
      } else {
        $(".company_company_default_vfnn").show();
        return $(select_vfnn).enableClientSideValidations();
      }
    });
  return $(modal_agency_id).trigger("change");
};

var filter_vfnn_by_agency_id = function (agency_id, modal_vfnn) {
  const vfnn_select = $(modal_vfnn);

  const vfnn_options = $(modal_vfnn + " " + "option");
  let selected_vfnn = "";

  vfnn_options.each(function () {
    if ($(this).attr("data-agency-id") === agency_id) {
      if ($(this).prop("selected")) {
        selected_vfnn = $(this).val();
      }
      return $(this).prop("selected", false).show().prop("disabled", false);
    } else {
      return $(this).prop("selected", false).hide().attr("disabled", true);
    }
  });

  if (agency_id) {
    $(".company_company_default_vfnn").show();
  } else {
    $(".company_company_default_vfnn").hide();
  }

  return vfnn_select.val(selected_vfnn).trigger("change");
};

var filter_company_by_sales_region = function (sales_region, modal_company) {
  const company_select = $(modal_company);
  const company_options = $(modal_company + " " + "option");
  if (sales_region === "") {
    return reset_company_selection(modal_company);
  } else {
    company_select.attr("readonly", false);
    let selected_company = "";
    company_options.each(function () {
      if (
        $(this).attr("data-sales-region") === sales_region ||
        $(this).val() === "" ||
        $(this).attr("data-sales-region") === ""
      ) {
        if ($(this).prop("selected")) {
          selected_company = $(this).val();
        }
        return $(this).prop("selected", false).show().prop("disabled", false);
      } else {
        return $(this).prop("selected", false).hide().attr("disabled", true);
      }
    });
    if (
      company_options.length ===
      $(modal_company + " " + "option:disabled").length
    ) {
      reset_company_selection(modal_company);
    }
    return company_select.val(selected_company).trigger("change");
  }
};

var reset_company_selection = function (modal_company) {
  const company_options = $(modal_company + " " + "option");
  company_options.each(function () {
    if ($(this).val() !== "") {
      return $(this).prop("selected", false).hide().attr("disabled", true);
    } else {
      return $(this).css("display", "block");
    }
  });
  return $(modal_company).attr("readonly", true);
};

const document_ready = function () {
  const crud_options = {
    company: {
      new: company_form_new_init,
      edit: company_form_edit_init,
      delete: company_delete_init,
    },
    "distributor-link": {
      new: distributor_link_form_new_init,
    },
  };
  init_datatable(crud_options);
  init_search();
  if ($("#companies-table").length > 0) {
    $.ajax({
      url: "/companies/company_export_fail_alert",
      type: "GET",
      success(data, status, xhr) {
        if (!sessionExpired(xhr)) {
          if (data["result"] !== "") {
            return show_error(data["result"]);
          }
        }
      },
    });
  }

  $(document).on("click", ".btn-alert-branch", function () {
    alert(I18n.t("no_link_target", { target: I18n.t("agency").toLowerCase() }));
    return false;
  });

  return $(document).on("click", ".btn-alert-distributor-link", function () {
    alert(
      I18n.t("no_link_target", { target: I18n.t("distributor").toLowerCase() })
    );
    return false;
  });
};

var init_datatable = (crud_options) =>
  dt.initDatatable($("#companies-table"), {}, crud_options);

var init_search = () =>
  $(".searchtoggle.companies").on("on_search_toggled", function () {
    init_advsearch("/companies/advfind");
    create_advfind_buttons("company");
  });

jQuery(() =>
  run_ontag("companies", function () {
    document_ready();
    if ($("#created").length) {
      setTimeout(refresh, 30000);
    }
    $(document).on("pjax:end", document_ready);
    if ($.support.pjax) {
      $.pjax.defaults.scrollTo = false;
      $.pjax.defaults.timeout = false;
      return $(document).pjax(".pagination a", "[data-pjax-container]");
    }
  })
);

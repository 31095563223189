document_ready = function () {
  var modal, agreement_form;
  modal = $("#agreement_model_name").val();
  init_agreement_date(modal);
  $("#software_license_title").html($("#agreement_type").find('option:selected').text())
  $("#agreement_type").off("change").on("change", function () {
      window.location.href =   "/software_license_agreements?agreement_type="+$(this).val();
        // $.ajax({
        //   url: "/software_license_agreements",
        //   type: "GET",
        //   data: { agreement_type: $(this).val()},
        //   success(data) {
        //     $("#agreement_form_holder").html(data);
        //   }
        // });
  });

  $('.license_agreement_form').off('click').on('click', function() {
    modal = $("#agreement_model_name").val();
    agreement_form = $(`#new_${modal}`);
     agreement_form
      .find("input, select, textarea")
      .each(function () {
        $(this).trigger("blur");
      });
    const agreement_form_validations = $(
      ClientSideValidations.selectors.forms[`agreement_form`]
    ).validate();
     if( agreement_form.isValid(agreement_form_validations) ) {
      var response = grecaptcha.getResponse();
      if( response.length > 0 ) {
        $(".agreement_captcha").hide();
      }else{
        $(".agreement_captcha").show();
        return false;
      }
    }
    else{
      return false;
    }
  });

  return country_select_init(modal, `#${modal}_country_code`);
};



init_agreement_date = function (modal) {
    if ($(`#${modal}_effective_date`).length > 0)
    {
      startDate = new Date($(`#${modal}_effective_date`).attr("data-range-start"));

      $(".datetimepicker").datetimepicker({
        format: "YYYY-MM-DD",
        ignoreReadonly: true,
        useCurrent: false,
        minDate: startDate,
      });
      return $(".clear-date")
        .off("click")
        .on("click", function () {
          $("#" + $(this).attr("for")).val("");
          return $("#" + $(this).attr("for"))
            .next(".bootstrap-datetimepicker-widget")
            .hide();
        });
    }
  };

jQuery(function() {
  run_ontag('software_license_agreements', function() {
    document_ready();
    $(".agreement_captcha").hide();
  });
});
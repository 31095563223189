var document_ready = function() {
  $(".onboarding_customer_form").val(I18n.t('register') );
  $("#onboarding_customer_language_type").off("change").on("change", function () {
    I18n.locale = $(this).val();
    assign_translations();
    relationship_customer();
    commercial_contact();
    invoice_currency();
    $(".onboarding_customer_form").val(I18n.t('register') );
  });
  $("#onboarding_customer_ultrasync_relationship").change(function() {
    remove_error_msg();
  })
  return country_select_init("onboarding_customer", "#onboarding_customer_country_code" );
};

var relationship_customer = function () {
  var arr=[I18n.t('onboarding_customer.relationship_dealer_label'), 
          I18n.t('onboarding_customer.relationship_cms_label_01'), 
          I18n.t('onboarding_customer.relationship_cms_label_02'), 
          I18n.t('onboarding_customer.relationship_label'),
          I18n.t('onboarding_customer.relationship_distributor_label')];
  $('#onboarding_customer_ultrasync_relationship option').each(function() {
    $(this).remove();
  });
  $('#onboarding_customer_ultrasync_relationship').append( "<option value=''>"+I18n.t('select')+"</option>");
  for (i = 1; i <= arr.length; i++)
  { 
    $('#onboarding_customer_ultrasync_relationship').append( "<option value="+i+">"+arr[i-1]+"</option>");
  }
}

var commercial_contact = function () {
  var arr_contact=[I18n.t('echo_yes'), I18n.t('echo_no') ];
  $('#onboarding_customer_can_we_contact option').each(function() {
    $(this).remove();
  });
  $('#onboarding_customer_can_we_contact').append( "<option value=''>"+I18n.t('select')+"</option>");
   for (i = 1; i <= arr_contact.length; i++)
  { 
    if( i == 1)
      {
        $('#onboarding_customer_can_we_contact').append( "<option value="+'Yes'+">"+arr_contact[i-1]+"</option>");
      } else {
        $('#onboarding_customer_can_we_contact').append( "<option value="+'No'+">"+arr_contact[i-1]+"</option>");
      }
  }     
}
var invoice_currency = function () {
  var arr_currency=[ "EUR", "USD", "GBP", "DKK", "NOK", "SEK", "PLN", "ZAR" ];
  $('#onboarding_customer_invoice_currency option').each(function() {
    $(this).remove();
  });
  $('#onboarding_customer_invoice_currency').append( "<option value=''>"+I18n.t('select')+"</option>");
   for (i = 1; i <= arr_currency.length; i++)
  { 
    $('#onboarding_customer_invoice_currency').append( "<option value="+i+">"+arr_currency[i-1]+"</option>");
  }     
}

var remove_error_msg = function () {
  if ($("#onboarding_customer_ultrasync_relationship").val().length > 0 && $('#onboarding_customer_ultrasync_relationship').next().hasClass('has-error') == true ){
    $('#onboarding_customer_ultrasync_relationship').parent().removeClass("has-error");
    $('#onboarding_customer_ultrasync_relationship').next().remove();
  }
}

var assign_translations = function () {
  $('.company_name').text(I18n.t('onboarding_customer.company_name'));
  $('.ultrasync_heading_01').text(I18n.t('onboarding_customer.ultrasync_heading_01'));
  $('.ultrasync_heading_02').text(I18n.t('onboarding_customer.ultrasync_heading_02'));
  $('.ultrasync_heading_03').text(I18n.t('onboarding_customer.ultrasync_heading_03'));
  $('.ultrasync_heading_04').text(I18n.t('onboarding_customer.ultrasync_heading_04'));
  $('.language').text(I18n.t('onboarding_customer.language'));
  $('.country_label').text(I18n.t('onboarding_customer.country'));
  $('.state_label').text(I18n.t('onboarding_customer.state'));
  $('.city_label').text(I18n.t('onboarding_customer.city'));
  $('.street_addr').text(I18n.t('onboarding_customer.street_address'));
  $('.zip_label').text(I18n.t('onboarding_customer.zip_code'));
  $('.relation_with_fs').text(I18n.t('onboarding_customer.relation_with_fs'));
  $('.signatory_contac').text(I18n.t('onboarding_customer.signatory_contac'));
  $('.signatory_email').text(I18n.t('onboarding_customer.signatory_email'));
  $('.commercial_no').text(I18n.t('onboarding_customer.commercial_no'));
  $('.ultrasync_username').text(I18n.t('onboarding_customer.ultrasync_username'));
  $('.ultrasync_email').text(I18n.t('onboarding_customer.ultrasync_email'));
  $('.electronic_invoice').text(I18n.t('onboarding_customer.electronic_invoice'));
  $('.group_email').text(I18n.t('onboarding_customer.group_email'));
  $('.oracle_number').text(I18n.t('onboarding_customer.oracle_number'));
  $('.fs_acct_manager').text(I18n.t('onboarding_customer.fs_acct_manager'));
  $('.can_we_contact_you').text(I18n.t('onboarding_customer.can_we_contact_you'));
  $('.cms_name').text(I18n.t('onboarding_customer.cms_name'));
  $('.distributor_name').text(I18n.t('onboarding_customer.distributor_name'));
  $('.invoicing_currency').text(I18n.t('onboarding_customer.invoicing_currency'));
  $("a.signatory-contac").attr("data-original-title", I18n.t('onboarding_customer.signatory_contac_helper'));
  $("a.group-email").attr("data-original-title", I18n.t('onboarding_customer.group_email_helper'));
  $("a.oracle-number").attr("data-original-title", I18n.t('onboarding_customer.oracle_number_helper'));
  $("a.acct-manager").attr("data-original-title", I18n.t('onboarding_customer.acct_manager_helper'));
  $("select[id=onboarding_customer_country_code] > option:first-child").text(I18n.t('onboarding_customer.country'));
  $("#onboarding_customer_state").attr('placeholder', I18n.t('onboarding_customer.state'));
};

jQuery(function() {
  run_ontag('onboarding_customers', function() {
    document_ready();   
    I18n.locale = "en";
    $("#onboarding_customer_language_type").prop('selectedIndex',0);
    assign_translations();
    $(".onboarding_captcha").hide();
  });

  relationship_customer();
  commercial_contact();
  invoice_currency();
  $('.onboarding_customer_form').off('click').on('click', function() {
    const current_company_id = $("#new_onboarding_customer");
     current_company_id
      .find("input, select, textarea")
      .each(function () {
        $(this).trigger("blur");
      });
    remove_error_msg();
    const form_validators = $(
      ClientSideValidations.selectors.forms[`current_company_id`]
    ).validate();
    var response = grecaptcha.getResponse();
    if( current_company_id.isValid(form_validators) ) {
      if( response.length > 0 ) {
        if ( $("#onboarding_customer_ultrasync_relationship").val().length > 0 ){
          $(".onboarding_captcha").hide();
          if ($('#onboarding_customer_ultrasync_relationship').next().hasClass('has-error') == true) {
            $('#onboarding_customer_ultrasync_relationship').parent().removeClass("has-error");
            $('#onboarding_customer_ultrasync_relationship').next().remove();
          }
          return true;
        }else{
          if (response.length > 0) {
            $(".onboarding_captcha").hide();
          }
          $('#onboarding_customer_ultrasync_relationship').parent().addClass(" has-error");
          $('#onboarding_customer_ultrasync_relationship').after("<div class='has-error'><span class='help-block'> can't be blank </span></div>" );
          return false;
        }
      }else{
        $(".onboarding_captcha").show();
        return false;
      }
    } else {
      return false;
    }
  });

  $(".onboarding-customer-reject").off('click').on('click', function() {
    $("#request_type_name").val("rejected");
    const current_company_id = $("#new_onboarding_customer");
    current_company_id.find("input, select").each(function () {
      $(this).trigger("blur");
    });
    const form_validators = $(
      ClientSideValidations.selectors.forms[`current_company_id`]
    ).validate();
    
    if( current_company_id.isValid(form_validators) && $("#onboarding_customer_reason").val().length  > 0 ) 
    {
      $('form#new_onboarding_customer').submit();
      return true;
    } else {
      if($("#onboarding_customer_reason").val().length == 0) {
        $('#onboarding_customer_reason').parent().addClass(" has-error");
        $('#onboarding_customer_reason').after("<div class='has-error'><span class='help-block'>" + I18n.t("onboarding_customer.rejection_reason") + "</span></div>" );
      }
      return false;
    }

  });
});